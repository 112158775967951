import React from "react";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Message, Item, Dropdown } from "semantic-ui-react";
import { Form, TextArea, Checkbox } from "formsy-semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableFilter } from "./DatatableFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import { DatatableRow } from "./DatatableRow";
import utilFunc from "../../../../../util_funs";

const tableHeader = [
  {
    title: "Actions",
    width: "1",
    sort: "",
  },
  {
    title: "Name",
    width: "3",
    sort: "name",
  },
  {
    title: "Email",
    width: "3",
    sort: "email",
  },
  {
    title: "Phone Number",
    width: "2",
    sort: "phone",
  },
  {
    title: "Default Jumpin Message",
    width: "1",
    sort: "",
  },
  {
    title: "Profile Image",
    width: "2",
    sort: "",
  },
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
const APIURL = "http://localhost/zyratalk/api/premium/settings/manageuser/";
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "user_id",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalErrorMsgLegacy: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      modalstatusNotiAandMisc: false,
      modalstatusNotiAandMiscData: {},
      ISDrenderOption1: [],
      ISDrenderOption2: [],
      notiAndSettingmodalData: {},
      loadNow: false,
      currentChecked: [],
      currentExpanded: [],
      hipaaLoginEmailList: [],
      hipaaLoginPhoneList: [],
      edit:false,
      imgError: "none",
      messageDisplay:"none"
    };
  }

  componentDidMount() {
    this.loadData({});

    // this.getAccessModules();
    api.user
      .getProfile()
      .then((resp) => {
        if (resp.data && resp.data.data.profileDetails) {
          const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
          const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
          this.setState({ ISDrenderOption1: defaultCountryCodeArr });
          this.setState({ ISDrenderOption2: remailCountryCodeArr });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  componentDidUpdate(prevProps, prevState){
    if(this.props.jumpin_newmsg!==prevProps.jumpin_newmsg){
      this.loadData({});
    }
  }
  
  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter) => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {
    if (data.modalType == "Delete") {
      this.onDeleteLiveChatAction();
    }
    // if (data.modalType == 'Popup') {
    //   this.setState({
    //     modalstatus: false,
    //     modaltitle: '',
    //     modalmessage: '',
    //     modalbuttonmsg: {},
    //     modalType: ''
    //   });
    // }
  };
  renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };
  renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : "qqq"}
        >
          {option.country_name}
        </option>
      );
    });
  };
  editMember = (id) => {
    this.setState({ loading: true });
        let recordlist = this.state.lists.findIndex((list) => list.id === id);
        let modaldata = {...this.state.lists[recordlist]};
        modaldata.imageStatus="FALSE";
        modaldata.existing_email=modaldata.email_id;
        modaldata.existing_phone_number=modaldata.phone_number;
        this.setState({
          modaldata: modaldata,
          modalbuttonmsg: { submit: "Submit", cancel: "Close" },
          modalstatus: true,
          modaltitle: "Edit notification member details",
          modalType: "",
          modalFooter: false,
          loading: false,
          edit:true
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
         
        });
     
  }
  onValidSubmitMember = () => {
    if(this.state.modaldata.email_id.trim()=="" && this.state.modaldata.phone_number.trim()==""){
      this.setState({
        messageDisplay: 'block',
        color: 'red',
        resMessage: "Both fields cannot be empty."
      }, () => {
        this.setState({
          modalmessage: this.memberEditBody()
        });
      });
      setTimeout(() => {
        this.setState({
          messageDisplay: 'none',
          color: 'green',
          resMessage: ""
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
      }, 4000);
      return false;
  }
    this.setState({
      loading: true,
      buttonDisable: true
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
    var myform = document.getElementById("manageNotificationIndiviNoti");
      var formData = new FormData(myform);
      formData.append("id", this.state.modaldata.id);
      formData.append("username", this.state.modaldata.username);
      formData.set('email_id',this.state.modaldata.email_id);
      formData.set('phone_number', this.state.modaldata.phone_number);
      formData.set('jump_in_question', this.state.modaldata.jump_in_question);
      formData.set('isd_code', this.state.modaldata.isd_code);
      formData.set('existing_email', this.state.modaldata.existing_email);
      formData.set('existing_phone_number', this.state.modaldata.existing_phone_number);
      formData.set('profile_pic', this.state.modaldata.profile_pic);
    api.manageUser.updateliveNotificationMember(formData).then((res) => {
      this.setState({ loading: false, buttonDisable: false })
      if (res.data.status) {
        this.setState({
          messageDisplay: 'block',
          color: 'green',
          resMessage: res.data.message
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
      } else {
        this.setState({
          messageDisplay: 'block',
          color: 'red',
          resMessage: res.data.message
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
      }
      setTimeout(() => {
        this.setState({
          messageDisplay: 'none'
        }, () => {
          this.setState({
            modalmessage: this.memberEditBody()
          });
        });
        if(res.data.error!=="duplicate" && res.data.status){
          this.loadData({});
          this.modalClose(false, "");
        }
       
      }, 2000);
    }).catch((err) => {
      console.log(err);
    })
  }
  memberEditBody = () => {
    
    let user_phone=typeof this.state.modaldata.phone_number!=="undefined" ? this.state.modaldata.phone_number.trim():"";
    console.log("name", this.state.modaldata,user_phone);
    const errorLabel = <div className="red" />;
    return (
      <div className="col-md-12">
        <Message
          color={this.state.color}
          style={{
            display: this.state.messageDisplay,
          }}
        >
          {this.state.resMessage}
        </Message>
        <Form
          noValidate
          autoComplete="off"
          className="manageNotificationIndiviNoti"
          id="manageNotificationIndiviNoti"
          onValidSubmit={this.onValidSubmitMember}
        >
          <div className="form-group">
            <label style={{ marginBottom: 0 }}>Full Name</label>
            <Form.Input
              type="text"
              className="1"
              name="username"
              validations="isRequired"
              validationErrors={{
                isRequired: "This field is required.",
              }}
              onChange={this.onchangeenable}
              errorLabel={errorLabel}
              defaultValue={this.state.modaldata.username}
            />
          </div>
          <div
            className="form-group"
            style={{ marginBottom: 10 }}
          >
            <label style={{ marginBottom: 0 }}>
              Email Address
            </label>
            {this.state.modaldata.email_id!="" ?  <Form.Input
                type="email"
                className=""
                name="email_id"
                validations="isEmail,isRequired"
                validationErrors={{
                  isEmail:
                    "Please enter a valid email address.",
                  isRequired: "This field is required.",
                }}
                errorLabel={errorLabel}
                onChange={this.onchangeenable}
                id="emailIndiviNoti"
                defaultValue={this.state.modaldata.email_id}
              />:<Form.Input
              type="email"
              className=""
              name="email_id"
              onChange={this.onchangeenable}
              id="emailIndiviNoti"
              defaultValue={this.state.modaldata.email_id}
            />}
            
          </div>
          <div
            className="form-group"
            style={{ marginBottom: 10 }}
          >
            <label>Phone Number</label>
            <div className="d-flex">
              <select
                class="form-control"
                name="isd_code"
                id="isd_code"
                onChange={this.handleChangeISD}
                style={{ maxWidth: 166, marginRight: "1rem" }}
                defaultValue={this.state.modaldata.isd_code}
              >
                {this.renderDropDownDefault(
                  this.props.ISDrenderOption1
                )}
                <optgroup label="Other countries">
                  {this.renderDropDownRemaining(
                    this.props.ISDrenderOption2
                  )}
                </optgroup>
              </select>
             {this.state.modaldata.phone_number!="" ? <Form.Input
                  fluid
                  autoComplete="off"
                  name="phone_number"
                  maxLength="13"
                  onChange={this.handlePhoneChange}
                  placeholder="Phone Number"
                  validations="isRequired,usPhoneLength"
                  validationErrors={{
                    isRequired: "This field is required.",
                    usPhoneLength:
                      "Phone number must be at least 10 numbers.",
                  }}
                  id="phoneNumberIndiviNoti"
                  className="w-100"
                  errorLabel={errorLabel}
                  defaultValue={this.state.modaldata.phone_number}
                />:<Form.Input
                fluid
                autoComplete="off"
                name="phone_number"
                maxLength="14"
                onChange={this.handlePhoneChange}
                placeholder="Phone Number"
                id="phoneNumberIndiviNoti"
                className="w-100"
                defaultValue={this.state.modaldata.phone_number}
              />}

              
            </div>
          </div>
          <div className="row">
                              <div
                                className="form-group col-md-6"
                                style={{ marginBottom: 10 }}
                              >
                                <label style={{ marginBottom: 10 }}>
                                  Default Jump In Message
                                </label>
                                <TextArea
                                  className=""
                                  validations="isRequired"
                                  validationErrors={{
                                    isRequired: "This field is required.",
                                  }}
                                  id="jump_in_question"
                                  placeholder="Default Jump In Message"
                                  rows="5"
                                  name="jump_in_question"
                                  onChange={this.onchangeenable}
                                  value={this.state.modaldata.jump_in_question}
                                ></TextArea>
                              </div>

                              <div className="form-group col-md-6">
                                <label>Profile Pic (optional)</label>
                                <div
                                  className="logo-img"
                                  style={{ height: 100, marginTop: 5 }}
                                >
                                  <img
                                    src={
                                      typeof this.state.modaldata.profile_pic=="undefined" || this.state.modaldata.profile_pic==="" ? "assets/media/logos/upload-bg.png" : this.state.modaldata.profile_pic.includes("blob") ? this.state.modaldata.profile_pic: this.props.sessionUser.assetPath +
                                      "/images/chatlogo/" +
                                      this.state.modaldata.profile_pic
                                    }
                                    alt=""
                                  />
                                  <input type="hidden" name="imageStatus" value={this.state.modaldata.imageStatus} />
                                </div>
                              
                                <div className="d-flex justify-content-center">
                                  <div className="upload-btn-wrapper">
                                    <button className="btn linear-gradient zy-save-btn width-100">
                                      Edit Logo
                                    </button>
                                    <input
                                      type="file"
                                      name="image"
                                      id="comanyLogoId"
                                      onChange={this.handleCompanyLogo}
                                      accept="image/jpg,image/png,image/jpeg,image/gif"
                                    />
                                  </div>
                                  {this.state.modaldata.profile_pic && (
                                    <button
                                      type="reset"
                                      className="btn linear-gradient zy-cancel-btn ml-2"
                                      onClick={this.handleCompanyLogoRemove}
                                    >
                                      Remove
                                    </button>
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: this.state.imgError,
                                    color: "red",
                                  }}
                                >
                                  Invalid file format. Only "jpg, jpeg, png,
                                  gif" files are supported.
                                </div>
                               
                                
                              </div>
                            </div>

          <div className="form-group" style={{ display: 'flex', marginTop: 10, columnGap: 10, justifyContent: 'right' }}>
            <button
              type="button"
              onClick={() => this.modalClose(false, "")}
              style={{ display: this.state.modalbuttonmsg.cancel != "" ? "block" : "none" }}
              className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
              data-dismiss="modal"
            >
              <span>Close</span>
            </button>
            <button
              disabled={this.state.buttonDisable}
              type="submit"
              // onClick={() => this.modalbuttofun(this.state.modalType)}
              style={{ display: this.state.modalbuttonmsg.submit != "" ? "block" : "none" }}
              className="btn linear-gradient yr-submitbtn btn-sm"
            >
              Submit
            </button>
          </div>
        </Form>
      </div>
    )
  }
  phoneFormat = (p) => {
    p = p.replace(/[^\d]*/gi, "");
    if (p.length <= 3) {
      return p;
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;
      return pp;
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      return finalPhone;
    }
  };

  handlePhoneChange = (e) => {
    const memberData = this.state.modaldata;

    let phone1 = e.target.value;
    let p = this.phoneFormat(phone1);
    memberData.phone_number = p;
    setTimeout(function () {
      document.getElementById("phoneNumberIndiviNoti").value = p;
    }, 100);
    this.setState({
      modaldata: memberData,
      email1: "",
      disableBtn: false
    },() => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  };

  handleChangeISD = (e) => {
    const memberData = this.state.modaldata;
    memberData.isd_code = e.target.value;
    this.setState({
      modaldata: memberData,
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  };
  onchangeenable = (e) => {
    const memberData = this.state.modaldata;
    memberData[e.target.name] = e.target.value;
    this.setState({
      modaldata: memberData,
    }, () => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  }

  handleCompanyLogo = (event) => {
    // let newState = this.state.customizeinfo;
    let imageMIME = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
    if (
      imageMIME.includes(event.target.files[0].type) === true &&
      event.target.files[0].type !== ""
    ) {
      // setImgError("none")
      var companyLogo = URL.createObjectURL(event.target.files[0]);
      const memberData = this.state.modaldata;
      memberData.profile_pic=companyLogo;
      memberData.imageStatus="TRUE";
      this.setState({
        imgError: "none",
        disableBtn: false,
        modaldata:memberData
      },() => {
        this.setState({
          modalmessage: this.memberEditBody()
        })
      });
    } else {
      this.setState({
        imgError: "block",
      });
    }
  };
  handleCompanyLogoRemove = (event) => {
    const memberData = this.state.modaldata;
    memberData.profile_pic="";
    memberData.imageStatus="FALSE";
    this.setState({
      imgError: "none",
      disableBtn: false,
      modaldata:memberData
    },() => {
      this.setState({
        modalmessage: this.memberEditBody()
      })
    });
  };

  deleteLiveChat = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.id === id);
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: "Delete",
      modaldata: modaldata,
    });
  };

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
      edit:false
    });
  };

  setCurrentCheck = (checked) => {
    this.setState({
      currentChecked: checked,
    });
  };
  setCurrentExpand = (checked) => {
    this.setState({
      currentExpanded: checked,
    });
  };

  onDeleteLiveChatAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageUser
      .deleteLegacyLiveChatMember({ id: this.state.modaldata.id })
      .then((data) => {
        this.setState({ modalFormLoader: false });
        try {
          let recordDeleted = this.state.lists.findIndex(
            (list) => list.id === data.data.id
          );
          let oldArr = this.state.lists;
          oldArr.splice(recordDeleted, 1);
          this.setState({
            modalstatus: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modalErrorMsg: false,
          });
          // this.props.showUserToaster(
          //   "green",
          //   "Legacy live chat notification member has been deleted successfully."
          // );
          //utilFunc.scrollTop(800);
          this.setState({
            loading: "none",
            message1: "block",
            color1: "green",
            msg1: "Legacy live chat notification member has been deleted successfully.",
          });
          this.props.UpdateToasterMsg(this.state);

          this.loadData({});
        } catch (err) {
          console.log(err);
        }
      });
  };

  loadData = (params) => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    // let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    // let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    // let time_zone = clientTimeZone;

    // params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.manageUser.getLiveChatLists(query).then((data) => {
        try {
          // let accessModuleStore = data.user_access;
          // data.user_access.forEach((item, index) => {
          //   item.forEach((item2, index2) => {
          //     accessModuleStore.push(item2);
          //   });
          // });
          let datatableRowstemp;
          var isd1 = "+1";
          var phoneno = "";
          var is_recieve_text_message_on_livechat = "0";
          var is_recieve_email_on_livechat = "0";
          var jumpin_message = "";
          var is_recieve_text_message_on_completechat = "0";
          var is_recieve_email_on_completechat = "0";
          var is_leave_note = "0";
          if (data.data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (
              <DatatableRow
                key={index}
                list={list}
                editUserSubAccounts={this.editUserSubAccounts}
                deleteLiveChat={this.deleteLiveChat}
                preOpensetting={this.preOpensetting}
                sessionUser={this.props.sessionUser}
                 editMember={this.editMember}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DialogBoxDetail
          obj={this.state}
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
          currentChecked={this.state.currentChecked}
          currentExpanded={this.state.currentExpanded}
          setCurrentCheck={this.setCurrentCheck}
          setCurrentExpand={this.setCurrentExpand}
          deleteModel={this.state.edit}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
        />
      </Segment>
    );
  }
}
