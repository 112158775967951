import React, { useState, useEffect, useRef } from 'react';
import classes from './CompanyInformation.module.css';
import ConfirmDialog from '../../../../../Modal/ConfirmDialog';

const CompanyInformation = (props) => {
    console.log('props.squad_setting', props.squad_setting);
    
    const [companyData, setCompanyData] = useState({});
    const [phoneNo, setPhoneNo] = useState('');
    const [prevNo, setPrevNo] = useState('');
    const [assistants, setAssistants] = useState([]); // State to hold the dynamic assistants
    const [confirmModal, setConfirmModal] = useState(false);
    const [currentDeleteBox, setCurrentDeleteBox] = useState(null);
    const lastAssistantRef = useRef(null); // Ref to the last assistant

    useEffect(() => {
        setAssistants(props.squad_setting);
    }, [props.squad_setting])

    
    useEffect(() =>{
        setCompanyData(props.companyInfo);
        },[props.companyInfo]);
        
        useEffect(()=>{
            setPrevNo(props.companyInfo.phoneNumber)
        },[])

    const handleCompanyInfoInput  = ( e ) => {
        if(e.target.name == 'limit'){
            e.target.value = e.target.value > 0 ? e.target.value : ''
        }
        let tempInfo = {...companyData};
        tempInfo[e.target.name] = e.target.value;
        props.setValue(tempInfo,'companyInfo');
    }
   
    
const handleDynamicNo = () => { 
    if(companyData.dynamicInsertNumber=='ON'){
        let phoneToggleInfo = {...companyData};
      phoneToggleInfo['dynamicInsertNumber']='OFF';
      props.setValue(phoneToggleInfo,'companyInfo')
      phoneToggleInfo['phoneNumber']=props.companyInfo.prevNumber;
  }

  if(companyData.dynamicInsertNumber=='OFF' || companyData.dynamicInsertNumber==undefined){
    //   setDynamicNo(true)
      let phoneToggleInfo = {...companyData};
      phoneToggleInfo['dynamicInsertNumber']='ON';
      phoneToggleInfo['phoneNumber']='';
      props.setValue(phoneToggleInfo,'companyInfo')
  }
  console.log(companyData.dynamicInsertNumber)
}
  


const handlePhoneNo = (e) =>{
        setPhoneNo(e.target.value)
        let phoneInfo = {...companyData};
        phoneInfo['phoneNumber']=e.target.value;
        phoneInfo['prevNumber']=e.target.value;
        props.setValue(phoneInfo,'companyInfo')
        console.log(phoneInfo)
}

    const handleAddAssistant = () => {
        props.setValue([...assistants, { message: '', description: '', workspaceId: '', assistantName: '' }], 'assistantsInfo');
        setAssistants([...assistants, { message: '', description: '', workspaceId: '', assistantName: '' }]);

        // Scroll to the newly added assistant
        setTimeout(() => {
            if (lastAssistantRef.current) {
                lastAssistantRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }, 100); // Add a small timeout to allow DOM update
    };

    const handleInputChange = (index, field, value) => {
        let updatedAssistants = null;
        if (field === 'assistant') {
            const selectedAssistant = props.turboList.filter((tl) => tl.wid == value);
            console.log(selectedAssistant, value);            
            updatedAssistants = assistants.map((assistant, i) =>
                i === index ? { ...assistant, workspaceId: selectedAssistant[0].wid, assistantName: selectedAssistant[0].name } : assistant
            );
        } else {
            updatedAssistants = assistants.map((assistant, i) =>
                i === index ? { ...assistant, [field]: value } : assistant
            );
        }
        props.setValue(updatedAssistants, 'assistantsInfo');
        setAssistants(updatedAssistants);
    };

    const handleDeleteAssistant = (index) => {
        setCurrentDeleteBox(index);
        setConfirmModal(true);        
    };

    const renderComapnyInfo = () => {
        return (
            <div className="row" un_key="0">
                <label className="col-md-4 pd10botbuilder">Company name</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input type="text" className="form-control" name='companyName' 
                        defaultValue={props.companyInfo.companyName}  
                        value={props.companyInfo.companyName} 
                        onChange={(event) => handleCompanyInfoInput(event)}
                        />
                    </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Phone number</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input 
                        disabled={companyData.dynamicInsertNumber == 'ON' ? true : false}
                        type="text" className="form-control" name='phoneNumber' 
                        defaultValue={props.companyInfo.phoneNumber}  
                        value={props.companyInfo.phoneNumber}
                        onChange={(event) => handlePhoneNo(event)}
                        placeholder='Phone Number'
                         />
                    </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Use dynamic number insertion</label>
                <div className="col-md-8">
                <div>
                    <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check">
                        <label>
                            <input checked={companyData.dynamicInsertNumber == 'ON' ? true : false} onChange={(e)=>handleDynamicNo()} type="checkbox" />
                            <span />
                        </label>
                    </span>
                </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Company website</label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input type="text" className="form-control" name='website' 
                        defaultValue={props.companyInfo.website}  
                        value={props.companyInfo.website}
                        onChange={(event) => handleCompanyInfoInput(event)}
                        />
                    </div>
                </div>
                <label className="col-md-4 pd10botbuilder">Maximum number of words per response<br/><strong>(We suggest 50 words)</strong></label>
                <div className="col-md-8">
                    <div className="form-group mb-2">
                        <input type="number" min="0" className="form-control" name='limit' 
                        defaultValue={props.companyInfo.limit ? props.companyInfo.limit : ''}  
                        value={props.companyInfo.limit ? props.companyInfo.limit : ''}
                        onChange={(event) => handleCompanyInfoInput(event)}
                        pattern='[0-9]'
                        onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
                        />
                    </div>
                </div>
                {props.botType === 'Voice_Turbo' &&
                    <div className='col-md-12'>
                        <div
                            className="kt-menu__link btn linear-gradient yr-submitbtn"
                            style={{ minHeight: 0, fontSize: '1rem', padding: 6, height: 30, cursor: 'pointer' }}
                            onClick={handleAddAssistant}
                        >
                            <span className="kt-menu__link-text">
                                <i className="fa fa-plus" aria-hidden="true"></i>Connect to additional assistants
                            </span>
                        </div>
                    </div>
                }
                <div className='clearfix'></div>
                {/* Assistants Box */}
                <div className='col-md-12'>
                    <div className={`row ${classes.assistantsBox}`}>
                        {assistants && assistants.map((assistant, index) => (
                            <div 
                                ref={index === assistants.length - 1 ? lastAssistantRef : null} // Reference to the last assistant 
                                key={index} 
                                className={`col-md-5 ${classes.assistantBox}`}
                            >
                                
                                {/* Delete Icon */}
                                <button
                                    onClick={() => handleDeleteAssistant(index)}
                                    className={classes.deleteButton}
                                    style={{ background: 'none', border: 'none', color: 'red', cursor: 'pointer' }}
                                >
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                                <label>Assistant<span className='text-danger'>*</span></label>
                                <select
                                    value={assistant.workspaceId}
                                    onChange={(e) => handleInputChange(index, 'assistant', e.target.value)}
                                    style={{ display: 'block', marginBottom: 10 }}
                                >
                                    <option value="">Select Assistant</option>
                                    {props.turboList.length && props.turboList
                                        .filter((tl) => tl.code !== props.workspace_id) // Filter the assistants
                                        .map((tl) => (
                                            <option key={tl.code} value={tl.wid}>{tl.name}</option>
                                        ))
                                    }
                                </select>
                                <label>Transfer message - Leave this blank for unnoticeable transfers</label>
                                <textarea
                                    value={assistant.message}
                                    onChange={(e) => handleInputChange(index, 'message', e.target.value)}
                                    style={{ display: 'block', marginBottom: 10, width: '100%', height: '80px', minHeight: '150px' }} // Adjust size as needed
                                />

                                <label>When should we transfer to this assistant?<span className='text-danger'>*</span></label>
                                <textarea
                                    value={assistant.description}
                                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                                    style={{ display: 'block', marginBottom: 10, width: '100%', height: '80px', minHeight: '150px' }} // Adjust size as needed
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <ConfirmDialog 
                    show={confirmModal}
                    toggleModal={saveQuestios}
                    closeAction={closeModal}
                    title={'Please confirm your action!'}
                    message={modelBody()}
                />
            </div>
        );
    };

    const saveQuestios = () => {
        const deletedAssistant = assistants[currentDeleteBox];
        const updatedAssistants = assistants.filter((_, i) => i !== currentDeleteBox);

        // Pass the deleted assistant's workspaceId to props.squadDeleted
        if (deletedAssistant) {
            props.squadDeleted(deletedAssistant.workspaceId);
        }
        
        props.setValue(updatedAssistants, 'assistantsInfo');
        setAssistants(updatedAssistants);
        setConfirmModal(false);
    }

    // close modal on click
    const closeModal = () => {
        setConfirmModal(false);
    }

    const modelBody = ( type ) => {
        return(
            <div>
                Are you sure you want to delete it?
            </div>
        )
    }

    return (
        <>
            <div className="box-title">Basic Info</div>
            {renderComapnyInfo()}
        </>
    );
};

export default CompanyInformation;
