import React from "react";
import "../../../Modal/dialog.css";
class Modal extends React.Component {

    render() {
        let brnparse = this.props.parsebtn == true ? "parseparent" : "";
        return (
            <React.Fragment>
                <div
                    className={this.props.show ? "modal completedchats fade show" : "modal completedchats fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    data-keyboard="false"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.props.show ? "block" : "none",zIndex: this.props.modelindex ?? '999' }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 600 }}>
                        {this.props.loading && <div className="ui loading form"></div>}
                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.props.onclose()}
                            >
                                X
						</button>
                            {this.props.title ? <div className="modal-header ">
                                <h5 style={{fontSize:"1.6rem", fontFamily:"Lato,Helvetica Neue,Arial,Helvetica,sans-serif"}} className="modal-title" >{this.props.title}</h5>
                                {(this.props.modaltranscript && this.props.isgpt==1) && <button class="btn linear-gradient yr-add-new float-right red onclick" onClick={()=>this.props.showtrainlogModal(this.props.auto_id)} data-toggle="modal" style={{background:'red',height:'40px',marginLeft:"-42px",lineHeight:'1.3rem',    boxShadow:'none'}}>Report This Chat For Training</button>}
                               
                                {this.props.modaltranscript && 
                                 this.props.modaltranscript
                                }
                            </div> :
                            <div className="modal-header ">
                                <h5 className="modal-title" >{this.props.title}</h5>
                                {(this.props.modaltranscript && this.props.isgpt==1) && <button class="btn linear-gradient yr-add-new float-right red onclick" onClick={()=>this.props.showtrainlogModal(this.props.auto_id)} data-toggle="modal" style={{height:'40px',lineHeight:'1.3rem',marginLeft:"-42px", background:'red',boxShadow:'none'}}>Report This Chat For Training {this.props?.custom_serach?.status}</button>}
                               
                                {this.props.modaltranscript && 
                                 this.props.modaltranscript
                                }
                                
                            </div>}
                            <div  className='modal-body parsedatap ' style={{paddingTop:"10px"}}><div style={{marginLeft:"14px"}}> 
                                {this.props?.custom_search  &&  Object.keys(this.props?.parsed_data).length>0 && !this.props?.title && this.props?.custom_search?.status=='ON' && <div className="send-button">
                                    <button className={this.props.parsebtnName+" btn linear-gradient yr-add-new  onclick "} onClick={()=>this.props.openCustomPopup(this.props?.custom_serach)} data-toggle="modal" style={{boxShadow:'none'}}>{this.props.custom_search.button_name}
                                    </button></div>}

                                {this.props.body}
                            </div>
                            </div>
                            {this.props.footer}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

export default Modal;
