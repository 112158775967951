import React from 'react';
import api from '../../../../../api';

class AddCallForward extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            transferCallValue: '',
            transferCallError: false,
            country_code: "+1",
            key_press: "1"
        }
    }
    componentDidMount() {
        this.onLoad();
    }

    componentDidUpdate(prevProps) {
        if (this.props.button_action_id != prevProps.button_action_id) {
            this.onLoad();
        }
    }
    onLoad = () => {
        if (this.props.branchDetails.callForwardRule.length) {
            this.setState({
                transferCallValue: this.props.branchDetails.callForwardRule[0].phone_number,
                transferCallError: false,
                country_code: this.props.branchDetails.callForwardRule[0].country_code,
                key_press: this.props.branchDetails.callForwardRule[0].key_digit
            })
        }
    };

    phoneFormat = (p) => {
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        } else if (p.length > 3 && p.length < 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;
            return pp;
        } else if (p.length >= 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            let l40 = pp.length;
            let p40 = pp.substring(0, 8);
            p40 = p40 + "-";

            let p41 = pp.substring(8, l40);
            let ppp = p40 + p41;
            let maxphonelength = 13;
            let finalPhone = ppp.substring(0, maxphonelength);
            return finalPhone;
        }
    };

    countryCodeHandler = (e) => {
        this.setState({ country_code: e.target.value })
    }

    keyPressHandler = (e) => {
        this.setState({ key_press: e.target.value })
    }

    handleTransferCall = (e) => {
        let inputValue;
        if (e.target.name == 'phone_for_transfer_call') {
            inputValue = this.phoneFormat(e.target.value);
            this.setState({
                transferCallError: false,
                transferCallValue: inputValue
            })
        }
    }

    saveForwardCall = (e) => {
       
        if (this.state.transferCallValue == "" || this.state.transferCallValue.length < 13) {
            this.setState({
                transferCallError: true,
            })
            setTimeout(() => {
                this.setState({
                    transferCallError: false,
                })
            }, 3000)
            return;
        }
        this.setState({ loading: true, disablebutton: true })
        api.botbuilder.saveCallFarwordNumber({
            workspace_id: this.props.branchDetails.details.workspace_id,
            branch_id: this.props.branchDetails.branch_id,
            key_pressed: this.state.key_press,
            country_code: this.state.country_code,
            phone_number: this.state.transferCallValue
        }).then((res) => {
            this.setState({ loading: false, disablebutton: false })
            this.props.popupClose()
            this.props.updateBranchDetails(res.data)
        }).catch((err) => {
            this.setState({ loading: false, disablebutton: false })
            console.log(err);
        })

    }


    render() {
        return (
            <div>
                {this.state.loading && (
                    <div className="ui loading form"></div>
                )}
                <div className="yr-chat-round-box yr-closebtn">
                    <button type="button" class="close linear-gradient" onClick={() => this.props.popupClose()}>&#10006;</button>
                    {
                        this.props.bot_type == 'Voice' ? 
                    <div className='col-md-12'>If someone presses
                        <select  
                            onChange={(e) => this.keyPressHandler(e)} 
                            value={this.state.key_press}
                            style={{ margin: "0px 5px", height: "30px", background: "white", border: "1px solid #cacaca", fontSize: "12px", borderRadius: "3px", color: "#959393" }}
                        >
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                        </select>
                            at any time during the call, forward the call to.
                        </div> : 
                        <div className='col-md-12'>If someone asks to speak to a human or representative, forward the call to: </div>
                    }
                    

                    <div className='col-md-12' style={{display: 'flex', columnGap: 10, marginTop: 10}}>
                            <select onChange={(e) => this.countryCodeHandler(e)} value={this.state.country_code} className='form-control'>
                                {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                    return (
                                        <option value={value.country_code} >{value.country_name}</option>
                                    )
                                })}
                                <optgroup label="Other countries">
                                    {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                        return (
                                            <option value={value.country_code}  >{value.country_name}</option>)
                                    })}
                                </optgroup>
                            </select>
                            <input
                                name="phone_for_transfer_call"
                                onChange={(e) => this.handleTransferCall(e)}
                                value={this.state.transferCallValue}
                                className='form-control'
                                type="text"
                                placeholder="(000)000-0000"
                            />
                    </div>
                    {this.state.transferCallError && <div style={{ color: "red", marginTop: "10px" }}>Please write a valid phone number</div>}
                </div>
                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="reset" class="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => this.props.popupClose()} ><span> Close </span></button>
                        <button type="submit" disabled={this.state.disablebutton} className="btn linear-gradient yr-submitbtn" onClick={(e) => this.saveForwardCall(e)}>Save</button>
                    </div>
                </div>
            </div>
        )
    }
}


export default AddCallForward;