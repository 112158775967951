import React from "react";
import { Form, Message, Input,Dropdown } from "semantic-ui-react";
import Validator from "validator";
import api from "../../api";
import Switch from "react-switch";
import utilFunc from "../../util_funs";

class Webhook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      webhook: "",
      webhookStatus: false,
      successMessage: null,
      errors: null,
      contentType: "multipart/form-data",
      workspaceId: null,
      workspaceBranches: null,      
      parsed_values: null,
      selectedWebhook: [],
      dynamicOptions: {}, // To store the options for each field dynamically
      selectedValues: {}, // To store selected values for dynamic dropdowns
    };
  }

  handleChange = (e) => {
    if (this.state.errors && this.state.errors.webhook) {
      this.setState({ errors: null, [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = () => {
    const errors = this.validateForm();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      let { webhookStatus, webhook, contentType, selectedWebhook,
        selectedValues, } = this.state;

      // Construct filters array and convert to JSON string
      const filters = 
        selectedWebhook.map((key) => ({
          field: key,
          selectedValue: selectedValues[key] || null, // Attach selected values for each key
        }));
      

      // Construct payload
      const payload = {
        webhookStatus,
        webhook: webhookStatus ? webhook : "",
        contentType,
        filters, // Filters as a JSON string
      };

      console.log('payload', payload);

      if (!webhookStatus) webhook = "";
      api.webhook.createWebhook(payload).then((resp) => {
        if (resp && resp.message) {
          this.setState({ successMessage: resp.message }, () => {
            utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
        }
      });
    }
  };

  validateForm() {
    const errors = {};
    if (this.state.webhookStatus) {
      if (!this.state.webhook) errors.webhook = "This field is required.";
      else if (!Validator.isURL(this.state.webhook)) {
        errors.webhook = "Invalid webhook url.";
      }
    }
    return errors;
  }
  handleSwitch = (checked) => this.setState({ webhookStatus: checked });
  // Handle dropdown change
  handleDropdownChange = (e, { value }) => {
    this.setState({ contentType: value });
  };
  componentDidMount() {
    // Load webhook details
    api.webhook.loadWebhook({}).then((resp) => {
      try {
      if (resp.webhook) {
        const webhookInfo = resp.webhook;

          // Load voice workspace branch details
          api.manageUser.getVoiceWorkspaceBranch({})
            .then((res) => {
              if (res.status) {
        this.setState({
                  workspaceId: res.data.workspaceId,
                  workspaceBranches: res.data.data,
                  voice_type: res.data.type,
                  parsed_values: res.data.parsed_values,
                }, () => {
                  console.log(webhookInfo.filters, typeof webhookInfo.filters);
                  
                  // Parse filters JSON string
                  const savedFilters = webhookInfo.filters ? JSON.parse(webhookInfo.filters) : [];

                  console.log('savedFilters', savedFilters, typeof savedFilters);
                  const selectedWebhook = savedFilters.map((filter) => filter.field);
                  const selectedValues = savedFilters.reduce((acc, filter) => {
                    acc[filter.field] = filter.selectedValue || null; // Default to null if no value
                    return acc;
                  }, {});

                  // Construct dynamicOptions based on parsed_values and savedFilters
                  const dynamicOptions = {};
                  selectedWebhook.forEach((field) => {
                    const parsedField = this.state.parsed_values?.find(
                      (item) => item.key === field
                    );
                    if (parsedField && Array.isArray(parsedField.val)) {
                      dynamicOptions[field] = parsedField.val.map((option, idx) => ({
                        key: idx,
                        text: option,
                        value: option.trim(),
                      }));
                    }
        });


                  this.setState({
                    webhook: webhookInfo.webhook_url || "", // Default to empty string if null
                    webhookStatus: webhookInfo.status === "active", // Convert status to boolean
                    contentType: webhookInfo.content_type || "multipart/form-data", // Default to multipart/form-data
                    selectedWebhook,
                    selectedValues,
                    dynamicOptions, // Add dynamic options to state
    });
                });
  }
            })
            .catch((err) => {
              console.error("Error fetching workspace branch data:", err);
            });


        }
      } catch (e) {
        console.error("Error processing webhook response:", e);
      }
    });


  }
  
  openPage() {
    window.open(
      "https://docs.google.com/document/d/1S0lzB7wsoTVpjsEhlnoAEIlPtY_XWnNer1zBmhcnax0/edit?usp=sharing"
    );
  }
  branchOption = () => {    
    let list = this.state.parsed_values.map((key, index) => {
      return {
        key: index,
        text: key.key,
        value: key.key,
      }
    });    
    return list;    
  }
  // Handle selection of main rules
  handleWebhookRule = (e, d) => {
    const selectedKeys = d.value;
    let dynamicOptions = {};

    // Loop through selected items and add dropdown options if `val` is non-empty
    selectedKeys.forEach((key) => {
      const field = this.state.parsed_values.find(item => item.key === key);
      if (field && Array.isArray(field.val) && field.val.length > 0) {
        dynamicOptions[key] = field.val.map((option, idx) => ({
          key: idx,
          text: option,
          value: option.trim()
        }));
      }
    });

    // Update state with selected keys and dynamic options
    this.setState({
      selectedWebhook: selectedKeys,
      dynamicOptions
    });
  };

  // Render additional dropdowns dynamically based on selected items
  renderDynamicDropdowns = () => {
    return this.state.selectedWebhook.map((key) => {
      if (this.state.dynamicOptions[key]) {
        return (
          <div className="col-lg-12 col-md-12 col-sm-12 form-group-sub" key={key}>
            <label>{`Webhook will trigger if ${key} option equals`}</label>
            <Form.Field>
              <Dropdown
                placeholder={`Choose ${key} value`}
                fluid
                selection
                multiple
                options={this.state.dynamicOptions[key]}
                value={this.state.selectedValues[key] || []} // Set pre-selected values
                onChange={(e, d) => this.handleDynamicSelection(key, d.value)}
              />
            </Form.Field>
          </div>
        );
      }
      return null;
    });
  };
    
  // Handle selection for each dynamic dropdown
  handleDynamicSelection = (key, value) => {
    this.setState((prevState) => ({
      selectedValues: {
        ...prevState.selectedValues,
        [key]: value, // Store selected values for each field
      },
    }));
  };

  render() {
    const errorLabel = <div className="red" pointing={"true"} />;
    const options = [
      { key: 'form', text: 'Form Data', value: 'multipart/form-data' },
      { key: 'json', text: 'JSON', value: 'application/json' },
    ];
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            <div className="kt-portlet__body kt-portlet__body--fit ">
              <div class="form-group row">
                <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub  ">
                  <button
                    type="submit"
                    style={{ float: "center" }}
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={this.openPage}
                  >
                    Webhook Tutorial Docs
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row  ">
                  <label class="form-control-label">Enable Webhook</label>
                  <div style={{ margin: "3px 175px 0 10px"}}>
                    <Switch
                      onChange={this.handleSwitch}
                      checked={this.state.webhookStatus}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
              </div>
              <Form autoComplete="off" onSubmit={this.handleSubmit}>
                {this.state.webhookStatus && (
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                      <label class="form-control-label">
                        Webhook Url <span>*</span>
                      </label>
                      <Form.Input
                        type="text"
                        autoComplete="off"
                        name="webhook"
                        placeholder="Webhook Url"
                        value={this.state.webhook}
                        onChange={this.handleChange}
                        error={
                          this.state.errors && this.state.errors.webhook
                            ? true
                            : false
                        }
                      />
                      {this.state.errors && (
                        <div className="red" pointing={"true"}>
                          {this.state.errors.webhook}
                        </div>
                      )}
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                    <Form.Field>
                      <label>Content Type</label>
                      <Dropdown
                        placeholder="Select Content Type"
                        fluid
                        selection
                        options={options}
                        onChange={this.handleDropdownChange}
                        value={this.state.contentType}
                      />
                    </Form.Field>
                     </div>
                    {this.state.parsed_values &&
                      <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                        <label>Add webhook filtering rule</label>
                        <Form.Field>                          
                          <Dropdown
                            placeholder="Select rule"
                            fluid
                            multiple
                            search
                            selection
                            options={this.branchOption()}
                            onChange={this.handleWebhookRule}
                            value={this.state.selectedWebhook}
                          />
                        </Form.Field>
                  </div>
                    }                    
                  {this.renderDynamicDropdowns()}

                  </div>
                )}
                <div className="form-group row">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                    <button
                      type="submit"
                      style={{ float: "right" }}
                      className="btn linear-gradient yr-submitbtn btn-sm"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Webhook;
