import React from 'react';
import CustomErrorField from "../../../../Form/CustomErrorField";
import api from "../../../../../api";
import { Message } from "semantic-ui-react";
import Modal from '../../../ChatSetup/components/Modal';
class LiveChatRule extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
          loading: false,
          branch_name:'',
          branch_question:'',
          errors:{},
          show:false,
          width:"900px",
          height:"auto",
          listdata:[],
          currenttime:[],
          team:[],
          showAvailablity:false,
          edit_id:'',
          add_data:{'name':'','email':'','isd_code':'+1','phone':'','jump_in_question':'Hi ,there human jumping in'},
          userToaster: false,
          userToasterMsg: "",
          userToasterColor: "",
          modalstatus:false  ,
          delete_id:''
          
  
    
        };
        
      } 

      componentDidMount() {
       
        this.onLoad();
    }
    
    componentDidUpdate(prevProps) {
     
        if(this.props.indextime!=prevProps.indextime){
          
           this.onLoad();
        }
      
      }

    onLoad=()=>{
               this.setState({show:this.props.livechatshow,loading:true});

               this.setState({loading:true})
               let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
               api.botbuilder.getLiveChatRules({ 'branch_id': this.props.branch_id,'workspace_id':this.props.workspace_id,'timezone':currentTimeZone }).then(data => {
       
                const timezone = data.data.timezone;
                try {
                    this.setState({
                        loading:false,
                        errors:{},
                        edit_id:'',
                        team:data.team,
                        listdata: data.data,
                        currenttime: data.data.currenttime,
                        add_data:{'name':'','email':'','isd_code':'+1','phone':'','jump_in_question':'Hi ,there human jumping in'}
                    });
    
                } catch (err) {
                    console.log(err);
                }


                
             
                 });

            }

    renderTimeZone = (list, selectedindex) => {
        if (typeof list != 'undefined') {

            return Object.keys(list).map(function (key, index) {
                return (
                    <option key={index} value={key}>{list[key]}</option>
                )
            });
        }
    }


    handleSelectChange = (e) => {
        let list = this.state.listdata
        let currentTimeZone = e.target.value;
        let default_val = e.target.value;
        let error= this.state.errors;
        if (e.target.value == '') {
            currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        }
        api.chatsetup.getCurrentTime({ 'timezone': currentTimeZone }).then(data => {
           
            try {
                list.selectedtimezone = default_val

                if(typeof error !='undefined' && error!=null  && error.hasOwnProperty('timezoneerror') && default_val!=''){
                    error['timezoneerror']=false;
                    
                }

                this.setState({
                    listdata: list,
                    currenttime: data.data.currenttime,
                    errors:error
                });

            } catch (err) {
                console.log(err);
            }

        });

    }
    handleTimeChange = (index, tab, e) => {
        let list = this.state.listdata
        let error= this.state.errors;
    
        if (index == 0) {

            list.list.forEach(function (item, i, arr) {
                
                if ((arr[i].working == null || arr[i].working == '') && (arr[i].off == null || arr[i].off == '')) {
                    arr[i][tab] = e.target.value

                    if(typeof error !='undefined' && error!=null && typeof error.errors!='undefined'  && error.errors.hasOwnProperty(i) && e.target.value!=''){
           
                        if(tab=='open'){
                            error.errors[i]['openerror']=false;
                        }
                        if(tab=='close'){
                            error.errors[i]['closeerror']=false;
                            error.errors[i]['timediff']=false;
                        }
                        
                    }


                }

            });
        } else {
            list.list[index][tab] = e.target.value
            if(typeof error !=undefined &&  error!=null && typeof error.errors!='undefined' && error.errors.hasOwnProperty(index) && e.target.value!=''){
           
                if(tab=='open'){
                    error.errors[index]['openerror']=false;
                }
                if(tab=='close'){
                    error.errors[index]['closeerror']=false;
                    error.errors[index]['timediff']=false;
                }
                
            }
        }
        
       
        this.setState({
            listdata: list,
            errors:error

        });
    }
    handleOnOff = (index, tab) => {
        
        let list = this.state.listdata;
        let error= this.state.errors;

        list.list[index][tab] = list.list[index][tab] == 'Y' ? null : 'Y';
        
        if (list.list[index][tab] == "Y") {
            list.list[index]['open'] = ""
            list.list[index]['close'] = ""
            if(tab=='working'){
                list.list[index]['off']=""
            }
            if(tab=='off'){
                list.list[index]['working']=""
            }
        }

        if(typeof error !='undefined' && error!=null &&  typeof error.errors !='undefined' &&error.errors.hasOwnProperty(index) && list.list[index][tab]=='Y'){
                error.errors[index]['openerror']=false;
                error.errors[index]['closeerror']=false;
                error.errors[index]['timediff']=false;
        }

        this.setState({
            listdata: list,
        },function(){
           
        });

        
    }


    handleSubmit = (e) => {
        e.preventDefault();

        
        let errors = this.validateForm(this.state.listdata);
        console.log(errors)

        if (errors.timezoneerror || errors.errors.length > 0 || typeof errors.name !='undefined' ||  typeof errors.emailphone !='undefined' ||  typeof errors.email !='undefined' ||  typeof errors.phone !='undefined' ) {

            this.setState({
                errors: errors,

            });
           
            var myElement = document.getElementById('timezone');
            var topPos = myElement.offsetTop;
            document.getElementById('livechatrules').scrollTop = topPos;
        } else {
            this.setState({
                errors: null,

            });
            let action='';
            let edit_id='';
            let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if(this.state.edit_id!=''){
                action='edit';
                edit_id=this.state.edit_id
            }
            let datatosend = {  
            'action':action,
            'edit_id':edit_id,
            'branch_id': this.props.branch_id,
            'workspace_id':this.props.workspace_id,
            'selectedtimezone': this.state.listdata.selectedtimezone, 
            'list': this.state.listdata.list,
            'add_data':this.state.add_data ,
            'timezone':currentTimeZone
        };
            
            this.setState({ loading: true });
            api.botbuilder.saveTimeZoneDataBranch(datatosend).then(data => {

                try {
                    
                    if(data.error==1 || !data.status){
                        this.setState({
                            errors: {email:data.message},
                            loading:false
            
                        },function(){
                           
                            var myElement = document.getElementById('timezone');
                            var topPos = myElement.offsetTop;
                           
                            document.getElementById('livechatrules').scrollTop = topPos;
                        });
                    }else{
                        let list_d= data.list_data;
                        list_d.selectedtimezone=''
                        this.setState({
                            userToaster: true,
                            userToasterMsg: data.message,
                            userToasterColor: "green",
                            loading:false,
                            edit_id:'',
                            team:data.team,
                            listdata: list_d,
                            showAvailablity:false,
                            currenttime: data.list_data.currenttime,
                            add_data:{'name':'','email':'','isd_code':'+1','phone':'','jump_in_question':'Hi ,there human jumping in'}
                        },function(){
                            var myElement = document.getElementById('team_data_id');
                            var topPos = myElement.offsetTop;
                            document.getElementById('livechatrules').scrollTop = topPos;
                            setTimeout(
                                function () {
                                    this.setState({ userToaster: false });
                                }.bind(this),
                                3000
                            );
                        });
                    }
                   

                } catch (err) {
                    console.log(err);
                }

            });
        }
    }

    resetForm=()=>{
        this.setState({ loading: true });
        let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        let datatosend = {  timezone:currentTimeZone};
        api.botbuilder.resetForm(datatosend).then(data => {

            try {

                this.setState({
                    loading:false,
                    edit_id:'',
                    errors:{},
                    listdata: data.list_data,
                    showAvailablity:this.state.showAvailablity,
                    currenttime: data.list_data.currenttime,
                    add_data:{'name':'','email':'','isd_code':'+1','phone':'','jump_in_question':'Hi ,there human jumping in'}
                });

            } catch (err) {
                console.log(err);
            }

        });
    }

    scroll=(element)=>{
        let scrolltop=document.querySelector(element).offsetTop - document.body.scrollTop;
        window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
    } 
   
    validateForm = (list) => {
        const errors = [];
        const reterror = {};
        
        let timezoneerror = false;
        let add_data_error=[];


        if(this.state.add_data.email=='' && this.state.add_data.phone==''){
          
            reterror.emailphone='Please select at least phone or email';
        }

        if(this.state.add_data.email!=''){

            var reg = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
            
               if(!reg.test(this.state.add_data.email)) {
               //errors['email']={[index]:'Please enter valid email address'} 
               reterror.email='Please enter valid email address';
           }
           
        }

        if(this.state.add_data.phone!=''){

           
            
               if(this.state.add_data.phone.length<13) {
               //errors['email']={[index]:'Please enter valid email address'} 
               reterror.phone='Please enter at least 13 characters.';
           }
           
        }

        if(this.state.add_data.name==''){

            reterror.name='Please select at least phone or email';
        
        
       }


       if(this.state.showAvailablity){

        if (list.selectedtimezone == '' || list.selectedtimezone == null) {
            timezoneerror = true;
        }
        reterror.timezoneerror = timezoneerror;
        list.list.map((x, y) => {
            if ((x.working == '' || x.working == null) && (x.off == '' || x.off == null)) {
                let openerror = false;
                let closeerror = false;
                let timediff = false;



                if (x.open == '' || x.open == null) {
                    openerror = true;
                }
                if (x.close == '' || x.close == null) {
                    closeerror = true;
                }
                
                if (this.calculateTime(x.open, x.close)) {
                    timediff = true
                }
                if (openerror || closeerror || timediff)
                    errors[y] = { 'openerror': openerror, 'closeerror': closeerror, 'timediff': timediff }
            }

        });
        }
        reterror.errors = errors;
       // alert(reterror)
        return reterror;
    }


    calculateTime = (start, end) => {
        var day = '1/1/1970 ', // 1st January 1970
            diff_in_min = (Date.parse(day + end) - Date.parse(day + start)) / 1000 / 60;
        var diff = false;
        if (diff_in_min < 0) {
            var diff = true;
        }
        return diff;
    } 
    inputChange=(e)=>{
        let add_data=this.state.add_data;
        if(e.target.name=='phone'){
            add_data[e.target.name]=this.phoneFormat(e.target.value);
        }else{
            add_data[e.target.name]=e.target.value;
        }
       
        this.setState({add_data:add_data})
    }  
    showAvailablity=()=>{
        let show=this.state.showAvailablity ? false:true
        // let errors=this.state.errors
        // errors.errors={}
        // errors.timezoneerror=false
        this.setState({showAvailablity:show,errors:null})
    }


    phoneFormat = (p) => { 
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        }else if (p.length > 3 && p.length < 7) {
          p = "(" + p;
          let l30 = p.length;
          let p30 = p.substring(0, 4);
          p30 = p30 + ")";
      
          let p31 = p.substring(4, l30);
          let pp = p30 + p31;
          return pp;
        } else if (p.length >= 7) {
          p = "(" + p;
          let l30 = p.length;
          let p30 = p.substring(0, 4);
          p30 = p30 + ")";
      
          let p31 = p.substring(4, l30);
          let pp = p30 + p31;
      
          let l40 = pp.length;
          let p40 = pp.substring(0, 8);
          p40 = p40 + "-";
      
          let p41 = pp.substring(8, l40);
          let ppp = p40 + p41;
          let maxphonelength = 13;
          let finalPhone = ppp.substring(0, maxphonelength);
          return finalPhone;
        }
      };

buttonAction=(tab,data,index)=>{
  if(tab=='edit'){
    this.setState({ loading: true });
    let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let datatosend = {editid:data,timezone:currentTimeZone};
   
    api.botbuilder.editBranchEmail(datatosend).then(data => {

        try {
            let showava=true;
            let list_d= data.list_data;
          if(data.shoavaialable==false){
            showava=false
            let list_d= data.list_data;
             list_d.selectedtimezone=''
          }
            this.setState({
                loading:false,
                errors:null,
                edit_id:data.details.id,
                listdata: list_d,
                showAvailablity:showava,
                currenttime: data.list_data.currenttime,
                add_data:{'name':data.details.username,'email':data.details.email_id,'isd_code':data.details.isd_code,'phone':data.details.phone_number,'jump_in_question':data.details.jump_in_question}
            },function(){
                let scrolltop=document.querySelector(".chatbox-padd").offsetTop - document.body.scrollTop;
                document.querySelector(".modal").scroll({top: scrolltop, left: 0, behavior: 'smooth' });
            });

        } catch (err) {
            console.log(err);
        }

    });
  }

  if(tab=='delete'){
    
    this.setState({ modalstatus: true,delete_id:data });
    
  }

}     

modalClose=()=>{
    this.setState({
      modalstatus: false,
      modaltitle: "",
      modalbody: "",
      modalindex: "",
      
    });
  }
modalFooter = (index) => {
    return (
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => this.modalClose()}
          className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
          data-dismiss="modal"
        >
          <span>No</span>
        </button>
        <button
          type="button"
          onClick={() => this.confirmDeleteEmail(this.state.delete_id)}
          className="btn linear-gradient yr-submitbtn btn-sm"
        >
          <span>Yes</span>
        </button>
      </div>
    );
    
  };

confirmDeleteEmail=(data)=>{
    this.setState({ loading: true });
    let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let datatosend = {'editid':data,'branch_id': this.props.branch_id,'workspace_id':this.props.workspace_id,timezone:currentTimeZone};
    api.botbuilder.deleteBranchEmail(datatosend).then(data => {
       this.modalClose();
        try {
        let list_d= data.list_data;
        list_d.selectedtimezone=''
            this.setState({
                userToaster: true,
                userToasterMsg: data.message,
                userToasterColor: "green",
                loading:false,
                edit_id:'',
                team:data.team,
                listdata: list_d,
                showAvailablity:false,
                currenttime: data.list_data.currenttime,
                add_data:{'name':'','email':'','isd_code':'+1','phone':'','jump_in_question':'Hi ,there human jumping in'}
            },function(){
                setTimeout(
                    function () {
                        this.setState({ userToaster: false });
                    }.bind(this),
                    3000
                );
            });

        } catch (err) {
            console.log(err);
        }

    });
}

render(){
    console.log(this.state.add_data)
    const {show,width,height,errors,currenttime,listdata}=this.state
    return(
        <React.Fragment>   

<div
          className={show!='' ? "modal fade show livechatrule" : "modal fade livechatrule"}
          data-backdrop="static"
          id="livechatrules"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="staticBackdrop"
          aria-hidden="true"
          style={{ display: show!='' ? "block" : "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            style={{ maxWidth: width }}
          >
            <div className="modal-content">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e)=>this.props.liveChatRules('close','','')}
              >
                X
              </button>
              <div className="modal-header">
                <h5 className="modal-title">Notifications</h5>
              </div>
              {this.state.loading && <div className="ui loading form"></div>}
              <div
                className="modal-body"
                style={{ maxHeight: height, overflowX: "auto" }}
              >
                

                {/* {this.state.loading && <div className="ui loading form"></div>} */}
            <div className="clearfixd" />
            <div className="yr-createTicket chatbox-padd">
                <div className="mb-3">
            Add/edit emails and SMS numbers to receive live chat notifications when this branch is triggered
            *Note* if you add yourself at multiple branches, you will receive only one notification during a chat
            </div>
            <form id="timezone" onSubmit={(e) => this.handleSubmit(e)} method="post" ref={this.myRef}>
            <div className="row formadata">																
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="text" className={
                                            errors && errors.name
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } name="name" placeholder="Full Name" onChange={(e)=>this.inputChange(e)} value={this.state.add_data.name}/>
                              {errors && errors.name && (
                                            <CustomErrorField
                                                message={errors.name}
                                                
                                                className="error invalid-feedback"
                                            />
                                        )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <input type="text" className={
                                            errors && errors.email
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } name="email" placeholder="Primary Email Address" disabled={this.state.edit_id!='' ? true : false} onChange={(e)=>this.inputChange(e)} value={this.state.add_data.email}/>
                        {errors && errors.email && (
                                            <CustomErrorField
                                                message={errors.email}
                                               
                                                className="error invalid-feedback"
                                            />
                                        )}
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                    <select name="isd_code" value={this.state.add_data.isd_code} style={{  width: "30%",display:"inline" ,marginRight:"2px"}}  className="form-control" onChange={(e)=>this.inputChange(e)}>

                        {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                            return (
                                <option value={value.country_code} >{value.country_name}</option>

                            )
                        })}

                        <optgroup label="Other countries">

                            {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                return (
                                    <option value={value.country_code}  >{value.country_name}</option>

                                )
                            })} </optgroup>
                        </select>
                        <input type="text" style={{  width: "69%",display:"inline"  }} value={this.state.add_data.phone} className={
                                            errors && errors.phone
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } name="phone" placeholder="Phone Number"  onChange={(e)=>this.inputChange(e)}/>
                        {errors && errors.phone && (
                                            <CustomErrorField
                                                message={errors.phone}
                                                
                                                className="error invalid-feedback"
                                            />
                                        )}
                    </div>
                    
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <textarea  className="form-control" name="jump_in_question" value={this.state.add_data.jump_in_question} placeholder="Default jump in Message" onChange={(e)=>this.inputChange(e)}></textarea>
                    </div>
                    <div className={
                                            errors && errors.emailphone
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } style={{display:"none"}}></div>
                    {errors && errors.emailphone && (
                                            <CustomErrorField
                                                message={errors.emailphone}
                                                
                                                className="error invalid-feedback"
                                            />
                                        )}  
                    </div>
                          

                    <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                        <a className="cursorpointer" onClick={()=>this.showAvailablity()}>Click to add availability</a>
                    </div>
                    </div>
                </div>
                </div>
                {this.state.showAvailablity &&
                <div className="row">
                <div className="col-lg-12 pr-5">
                    <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                        <label htmlFor="exampleSelectd">Select Time Zone<span className="required">*</span></label>
                        <select className={
                                            errors && errors.timezoneerror
                                                ? "form-control is-invalid"
                                                : "form-control"
                                        } id="exampleSelectd" value={listdata.selectedtimezone} onChange={(e) => this.handleSelectChange(e)}>
                                            <option value="">Select Time Zone</option>
                                            {this.renderTimeZone(listdata.timezone, listdata.selectedtimezone)}
                                        </select>
                                        {currenttime &&
                                            <span className="form-text text-muted">The local time is currently {currenttime}  </span>
                                        }

                                        {errors && errors.timezoneerror && (
                                            <CustomErrorField
                                                message="This field is required"
                                                id="timezne"
                                                className="error invalid-feedback"
                                            />
                                        )}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  
                    <div className="form-group row px-4 mb-0 yr-scrll-x">
                    { listdata.list && listdata.list.map((obj, index) => {
                                   
                                   return (
                                       <ul className="yr-time-ShowHide" key={index}>
                                           <li><label className="label-color">{obj.day}</label></li>
                                           <li>
                                               <div className="form-group">
                                                   <label className="drop-label">Open</label>
                                                   <span className="custom-arrow">
                                                       <select className={
                                                           errors && typeof errors.errors!='undefined' && errors.errors.length > 0 && errors.errors.hasOwnProperty(index) && errors.errors[index].openerror
                                                               ? "form-control is-invalid mat-style"
                                                               : "form-control mat-style"
                                                       } name="" value={obj.open} onChange={(e) => this.handleTimeChange(index, 'open', e)} disabled={obj.working == 'Y' || obj.off == 'Y' ? true : null}>
                                                           <option value="" >Select</option>
                                                           {Object.keys(listdata.hours).map(function (key, index) {
                                                               return (
                                                                   <option key={index} value={key}>{listdata.hours[key]}</option>
                                                               );
                                                           })}


                                                       </select>
                                                      
                                                       {errors && typeof errors.errors!='undefined' && errors.errors.length > 0 && errors.errors.hasOwnProperty(index) && errors.errors[index].openerror && (
                                                           <CustomErrorField
                                                               message="This field is required"
                                                               id="help-error"
                                                               className="error invalid-feedback"
                                                           />
                                                       )}

                                                   </span>

                                               </div>
                                           </li>
                                           <li>
                                               <div className="form-group">
                                                   <label className="drop-label">Unavailable</label>
                                                   <span className="custom-arrow">
                                                       <select className={
                                                           errors && typeof errors.errors!='undefined' && errors.errors.length > 0 && errors.errors.hasOwnProperty(index) && (errors.errors[index].closeerror || errors.errors[index].timediff)
                                                               ? "form-control is-invalid mat-style"
                                                               : "form-control mat-style"
                                                       } name="" value={obj.close} onChange={(e) => this.handleTimeChange(index, 'close', e)} disabled={(obj.working == 'Y' || obj.off == 'Y') ? true : null}>
                                                           <option value="" >Select</option>
                                                           {typeof listdata.hours != 'undefined' && Object.keys(listdata.hours).map(function (key, index) {
                                                               return (
                                                                   <option key={index} value={key} >{listdata.hours[key]}</option>
                                                               );
                                                           })}

                                                           <option value="11:59 PM" >11:59 PM</option>
                                                       </select>
                                                       {errors && typeof errors.errors!='undefined' &&  errors.errors.length > 0 && errors.errors.hasOwnProperty(index) && (errors.errors[index].closeerror || errors.errors[index].timediff) && (
                                                           <CustomErrorField
                                                               message={errors.errors[index].timediff ? 'Closed time will be greater than open time' : 'This field is required'}
                                                               id="help-error"
                                                               className="error invalid-feedback"
                                                           />
                                                       )}
                                                   </span>
                                               </div>
                                           </li>
                                           <li>
                                               <label className="kt-checkbox chat-checkbox">
                                                   <input type="checkbox" name="hide" checked={obj['off'] == 'Y' ? true : false} onChange={() => this.handleOnOff(index, 'off')} /> Unavailable
                               <span />
                                               </label>
                                           </li>
                                           <li>
                                               <label className="kt-checkbox chat-checkbox">
                                                   <input type="checkbox" name="show" checked={obj['working'] == 'Y' ? true : false} onChange={() => this.handleOnOff(index, 'working')} /> Available all day
                               <span />
                                               </label>
                                           </li>
                                       </ul>)
                               })}																	
                    </div>																	
                   
                    
                </div>
                </div>
                 }
                  <div className="row">
                        <div className="col-sm-12 d-flex justify-content-end yr-mt-20" style={{paddingRight: 0}}>
                        <a className="btn linear-gradient zy-save-btn width-100 cursorpointer" style={{marginRight:"10px",color:"white"}} onClick={()=>this.resetForm()}>Reset</a>
                <button className="btn linear-gradient zy-save-btn width-100">{this.state.edit_id!='' ? 'Update': 'Save'}</button>
                        
                        </div>
                    </div>
                </form>
            </div>

            <Message
                    color={
                        this.state.userToasterColor
                            ? this.state.userToasterColor
                            : "teal"
                    }
                    style={{
                        display: this.state.userToaster ? "block" : "none",
                        marginTop: 30,
                    }}
                >
                    {this.state.userToasterMsg}
                </Message>

            <div className="yr-createTicket">
                    <div className="timezonedata-table">
                    <table class="striped table timezonetable" id="team_data_id">
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email</th>
                                    <th>Phone No</th>
                                    <th>Default jump in Message</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.team.length>0 && this.state.team.map((obj,index)=>{

                                return (
                                <tr>
                                    <td>{obj.username}</td>
                                    <td>{obj.email_id}</td>
                                    <td>{obj.isd_code} {obj.phone_number}</td>
                                    <td>{obj.jump_in_question}</td>
                                    <td>                                        
                                        <button type="button" title="Edit" className="btn btn-icon"><i className="la la-edit" onClick={()=>this.buttonAction('edit',obj.id,index)}/></button>
                                        <button type="button" title="Delete" className="btn btn-icon"><i className="la la-trash red"  onClick={()=>this.buttonAction('delete',obj.id,index)}/></button>
                                    </td>
                                </tr>
                                )
                              })}
                               
                            </tbody>
                        </table>
                    </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                  data-dismiss="modal"
                  onClick={(e)=>this.props.liveChatRules('close','','')}
                >
                  <span>Close</span>
                </button>
                
              </div>
            </div>
          </div>

          <Modal
                                      show={this.state.modalstatus}
                                      footer={this.modalFooter()}
                                      onclose={this.modalClose}
                                      title='Confirm your action'
                                      body='Are you sure you want to delete?'
            />



        </div>




        </React.Fragment>
    )
}
}


export default LiveChatRule;
