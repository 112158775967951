import React, { useEffect, useState, useRef } from 'react'
import OpenAndCloseHour from './OpenAndCloseHour'
import { Item, Message, Dropdown } from "semantic-ui-react";
import jstz from "jstz";
import api from '../../../../../api';
import ScheduleCalendar from './ScheduleCalendar';
import { useSelector } from "react-redux";
const TimeZone = (props) => {
    useEffect(() => {
        getVoiceTimeZoneData();
        getHolidaysList();
    }, []);

    const userData = useSelector((state) => state.user.company);
    const childRef = useRef();
    const [reloadSchedule, setReloadSchedule] = useState(false);

    useEffect(() => {
            childRef.current.loadData({});
            setReloadSchedule(false);
    }, [reloadSchedule]);

    
    const [userToasterColor, setUserToasterColor] = useState('');
    const [userToaster, setUserToaster] = useState(false);
    const [userToasterMsg, setUserToasterMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [openIndex, setOpenIndex] = useState(null);
    const [companyScheduleSaveDisabled, setCompanyScheduleSaveDisabled] = useState(false);
    const [companyHolidayList, setcompanyHolidayList] = useState([]);
    const [savedcompanyHolidayList, setsavedcompanyHolidayList] = useState([]);
    const [timeZoneData, setTimeZoneData] = useState({
        timeZone: null,
        currenttime: '',
        currentSelectedTimezone: '',
        listdata: [],
        hours: [],
        holidays:[]
    });

    const reloadScheduleAfterMember = () => {
        setReloadSchedule(true);
        setTimeout(() => {
            setReloadSchedule(false);
        }, 3000);
    }

    const getVoiceTimeZoneData = () => {
        var timezoneObj = jstz.determine();
        api.manageUser.getUserVoiceHourData({ timezonename: timezoneObj.name() }).then((res) => {
            if (res.status) {
                setTimeZoneData({
                    timeZone: res.data.data.timezone,
                    currenttime: res.data.data.currenttime,
                    currentSelectedTimezone: res.data.data.selectedtimezone,
                    listdata: res.data.data.list,
                    hours: res.data.data.hours,
                });
                setsavedcompanyHolidayList(res.data.data.scheduledHoliday);
            }
        }).catch((err) => {
            console.log(err);
        })

    }

    const getHolidaysList = () => {
        api.botbuilder.getHolidaysList( {workspace_id:null} ).then( ( res ) => {
            setcompanyHolidayList(res.holidays.data);
        });

    }

    const onHandleOnOff = (index, type) => {
        const hourData = timeZoneData.listdata;
        const allErrors = errors;
        hourData[index][type] = hourData[index][type] == 1 ? 0 : 1;
        if (hourData[index][type] == 1) {
            hourData[index]["open_hour_start"] = "";
            hourData[index]["open_hour_end"] = "";
            hourData[index]["after_hour_start"] = "";
            hourData[index]["after_hour_end"] = "";
            hourData[index]["open_hour_start_index"] = "";
            hourData[index]["open_hour_end_index"] = "";
            hourData[index]["after_hour_start_index"] = "";
            if (type == "working") {
                hourData[index]["closed"] = "";
            }
            if (type == "closed") {
                hourData[index]["working"] = "";
            }
        }
        setTimeZoneData({
            ...timeZoneData,
            listdata: hourData
        });
    }

    const convertTime = timeStr => {
        const [time, modifier] = timeStr.split(' ');
        let [hours, minutes] = time.split(':');
        if (hours === '12') {
            hours = '00';
        }
        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }
        return `${hours}:${minutes}`;
    };

    const onHandleTimeChange = (index, type, e) => {
        const hourData = timeZoneData.listdata;
        const allErrors = errors;
        hourData[index][type] = e.target.value;
        if (type === 'open_hour_start') {
            hourData[index].open_hour_end = '';
            const open_hour_start_index = Object.keys(timeZoneData.hours).findIndex((item, index) => item == e.target.value);
            if (open_hour_start_index) {
                hourData[index].open_hour_start_index = open_hour_start_index;
            }
            if (index == 0) {
                hourData.forEach((value, i) => {
                    if (!hourData[i].closed && !hourData[i].working) {
                        hourData[i][type] = e.target.value;
                        hourData[i].open_hour_start_index = open_hour_start_index;
                        hourData[i].open_hour_end = '';
                    }
                })    
            }
        }

        if (type === 'open_hour_end') {
            const open_hour_end_index = Object.keys(timeZoneData.hours).findIndex((item, index) => item == e.target.value);
            if (open_hour_end_index) {
                hourData[index].open_hour_end_index = open_hour_end_index;
            }
            if (index == 0) {
                hourData.forEach((value, i) => {
                    if (!hourData[i].closed && !hourData[i].working) {
                        hourData[i][type] = e.target.value;
                        hourData[i].open_hour_end_index = open_hour_end_index;
                        hourData[i].after_hour_start = '';
                        hourData[i].after_hour_end = '';
                    }
                })    
            }
            if(hourData[index].open_hour_start && hourData[index].open_hour_end){
                const openStat24 = convertTime(hourData[index].open_hour_start);
                const openEnd24 = convertTime(hourData[index].open_hour_end);

                // Parse the selected start time to get the hour and minute
                const [startHour, startMinute] = openStat24.split(':').map(part => parseInt(part));
                const [endHour, endMinute] = openEnd24.split(':').map(part => parseInt(part));

                const timeDifference = 30;

                // get after hours time
                const afterHours = calculateAfterHours(startHour, startMinute, endHour, endMinute, timeDifference);                

                // update after hours list
                hourData[index].after_hour_start = afterHours.afterHoursStart;
                hourData[index].after_hour_end = afterHours.afterHoursEnd;
            }
            if (hourData[index].open_hour_end == '' || hourData[index].open_hour_start == '') {
                // update after hours list
                hourData[index].after_hour_start = '';
                hourData[index].after_hour_end = '';
            }
        }
        if (type === 'after_hour_start') {
            hourData[index].after_hour_end = '';
            const after_hour_start_index = Object.keys(timeZoneData.hours).findIndex((item, index) => item == e.target.value);
            if (after_hour_start_index) {
                hourData[index].after_hour_start_index = after_hour_start_index;
            }
        }
        // if (hourData[index].open_hour_start && hourData[index].open_hour_end && (hourData[index].after_hour_start == '' && hourData[index].after_hour_end == '')) {
        //     const openStat24 = convertTime(hourData[index].open_hour_start);
        //     const openEnd24 = convertTime(hourData[index].open_hour_end);

        //     // Parse the selected start time to get the hour and minute
        //     const [startHour, startMinute] = openStat24.split(':').map(part => parseInt(part));
        //     const [endHour, endMinute] = openEnd24.split(':').map(part => parseInt(part));

        //     const timeDifference = 30;

        //     // get after hours time
        //     const afterHours = calculateAfterHours(startHour, startMinute, endHour, endMinute, timeDifference);
        //     console.log(afterHours);

        //     // update after hours list
        //     hourData[index].after_hour_start = afterHours.afterHoursStart;
        //     hourData[index].after_hour_end = afterHours.afterHoursEnd;
        // }

        setTimeZoneData({
            ...timeZoneData,
            listdata: hourData
        });
    }

    const saveCompanySchedule = () => {
        setLoading(true);
        let tempsavedcompanyHolidayList = [...savedcompanyHolidayList];
        tempsavedcompanyHolidayList.map( (holiday,i) => {
            if(!holiday){
                holiday = {};
            }
            let stirngToArray = companyHolidayList[i].id.split('-');
            holiday.event_id = stirngToArray[1];
            holiday.date = companyHolidayList[i].data;
            return holiday;
        })
        const postData = { timeZoneValue : timeZoneData.timeZone[timeZoneData.currentSelectedTimezone],
            timeZone: timeZoneData.currentSelectedTimezone,
            listData: timeZoneData.listdata,
            savedcompanyHolidayList:tempsavedcompanyHolidayList
        };
        api.manageUser.saveVoiceCompanySchedule(postData).then((res) => {
            if (res.status) {
                setUserToasterMsg('Company schedule successfully saved.');    
                setUserToaster(true);
                setLoading(false);
                setReloadSchedule(true);
                document.getElementById('userToasterMessage').scrollIntoView();
                setTimeout(() => {
                    setUserToasterMsg('');    
                    setUserToaster(false);
                }, 3000);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    const handleTimeZoneSelect = (e, { value }) => {
        const timeZoneName = value;
        const timeZoneValue = timeZoneData.timeZone[timeZoneName];        
        let currentTime = new Date().toLocaleString("en-US", { timeZone: timeZoneName });
        setTimeZoneData({
            ...timeZoneData,
            currentSelectedTimezone: timeZoneName,
            currenttime: currentTime,
            timeZoneValue: timeZoneValue
        });
    }

    const convertTo12HourFormat = (hour) => (hour % 12) || 12;

    const calculateAfterHours = (openHour, openMinute, closeHour, closeMinute, timeDifference) => {
        // Calculate the close time in minutes
        const closeTimeInMinutes = closeHour * 60 + closeMinute;

        // Calculate the after-hours start time (add time difference to the close time)
        const afterHoursStartInMinutes = closeTimeInMinutes + timeDifference;

        // Calculate the after-hours end time (e.g., end of the day at 11:59 PM)
        const afterHoursEndInMinutes = 23 * 60 + 59;

        // Convert back to hours and minutes in 24-hour format
        const afterHoursStartHour = Math.floor(afterHoursStartInMinutes / 60);
        const afterHoursStartMinute = afterHoursStartInMinutes % 60;

        const afterHoursEndHour = Math.floor(afterHoursEndInMinutes / 60);
        const afterHoursEndMinute = afterHoursEndInMinutes % 60;

        // Return an object with after-hours start and end times
        return {
            afterHoursStart: `${convertTo12HourFormat(afterHoursStartHour)}:${(afterHoursStartMinute < 10 ? '0' : '')}${afterHoursStartMinute} ${(afterHoursStartHour < 12) ? 'AM' : 'PM'}`,
            afterHoursEnd: `${convertTo12HourFormat(afterHoursEndHour)}:${(afterHoursEndMinute < 10 ? '0' : '')}${afterHoursEndMinute} ${(afterHoursEndHour < 12) ? 'AM' : 'PM'}`
        };
    };

    const timeZoneOption = () => {
        let list = Object.keys(timeZoneData.timeZone).map((key, index) => {
            return {
                key: index,
                text: timeZoneData.timeZone[key],
                value: key
            }
        });
        return list;
    }

    const handleHolidayTimeChange = (index, type, event) => {
        
        let savedcompanyHolidayListtemp = [...savedcompanyHolidayList];
       let newValue = (typeof event === 'object') ? event.target.value : event;
        // Ensure the index exists in the array
        if (!savedcompanyHolidayListtemp[index]) {
            savedcompanyHolidayListtemp[index] = {};
        }
        
        // Set the value for the given type
        if(type == 'working' || type == 'closed'){
            savedcompanyHolidayListtemp[index] = {...savedcompanyHolidayList[index]}
            if( savedcompanyHolidayListtemp[index][type] == 'on'){
                delete savedcompanyHolidayListtemp[index][type];
            }else{
                savedcompanyHolidayListtemp[index][type] = newValue;
            }
            if( type == 'working' && savedcompanyHolidayListtemp[index]?.closed ){
                savedcompanyHolidayListtemp[index].closed = "";
            }
            if( type == 'closed' && savedcompanyHolidayListtemp[index]?.working ){
                savedcompanyHolidayListtemp[index].working = "";
            }
            savedcompanyHolidayListtemp[index].open_hour_start = "";
            savedcompanyHolidayListtemp[index].open_hour_end = "";
        }else{
            savedcompanyHolidayListtemp[index] = {...savedcompanyHolidayList[index],[type]:newValue}
            savedcompanyHolidayListtemp[index].working = "";
            if(savedcompanyHolidayListtemp[index]?.closed){
                savedcompanyHolidayListtemp[index].closed = "";
            }
        }
        setsavedcompanyHolidayList(savedcompanyHolidayListtemp);
    }

    return (
        <>
            <div id='userToasterMessage' className="yr-createTicket chatbox-padd yr-chat-setup">
                <form>
                    <div className='row'>
                        {loading && <div className="ui loading form"></div>}
                        <div className='col-md-12'>
                            <Message
                                color={
                                    userToasterColor ? userToasterColor : "teal"
                                }
                                style={{
                                    display: userToaster ? "block" : "none",
                                    marginTop: 30,
                                }}
                            >
                                {userToasterMsg}
                            </Message>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label>Set your company schedule</label>
                                {timeZoneData.timeZone &&
                                    <div style={{ display: 'flex', columnGap: 20 }}>
                                        <Dropdown 
                                            placeholder='Select Time Zone'
                                            search
                                            selection
                                            options={timeZoneOption()}
                                            value={timeZoneData.currentSelectedTimezone} 
                                            onChange={handleTimeZoneSelect}
                                        />
                                        <p>Current Time: {timeZoneData.currenttime}</p>
                                    </div>
                                }
                            </div>
                        </div>
                        {timeZoneData.listdata &&
                            <div className='col-md-12'>
                                <OpenAndCloseHour
                                    list={timeZoneData.listdata}
                                    hours={timeZoneData.hours}
                                    handleOnOff={onHandleOnOff}
                                    handleTimeChange={onHandleTimeChange}
                                    holidayList={companyHolidayList}
                                    handleHolidayTimeChange={handleHolidayTimeChange}
                                    savedcompanyHolidayList={savedcompanyHolidayList}
                                />
                                <div className="clearfix"></div>
                                <button
                                    className="btn linear-gradient yr-add-new float-right"
                                    disabled={companyScheduleSaveDisabled}
                                    onClick={saveCompanySchedule}
                                    type='button'
                                >
                                    Save
                                </button>
                            </div>
                        }
                    </div>
                </form>
            </div>
            <div className="yr-createTicket chatbox-padd yr-chat-setup">
                <div className='row'>
                    <ScheduleCalendar
                        ref={childRef}
                        userData={userData}
                        hourList={timeZoneData.hours}
                        timeZoneData={timeZoneData}
                    />
                </div>
            </div>
        </>
    )
}

export default TimeZone
