import React,{useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Segment, Table,Pagination, Message, Item, Dropdown } from "semantic-ui-react";
import { Form, TextArea, Checkbox } from "formsy-semantic-ui-react";
import {DatatableTableHeader} from './DataTable'
import api from "../../../../../api";
import { DatatableRow } from "./DatatableRow";
import "../../../../Grid/grid.css";
import {setTableData,setParams,setmodeldata}  from '../../../../../store/incompleteChat';
import { DatatablePageSizeSelect } from './DatatablePageSizeSelect';
import DialogBoxDetail from './DialogBoxDetail';
function IncompleteChat(props){
   
    const state = useSelector((state) => state.incompletechat);
    console.log("init",state);
    const dispatch = useDispatch();
    const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
    const [datatableRows,setdatatableRows] = useState([]);
    
    useEffect(() => {
        loadData(state.params);
    },[state.data.totalrecord]);
    const loadData = (params) => {
        const newState = Object.assign({}, datatableRows, params, { loading: false });
        const esc = encodeURIComponent;
        const query = Object.keys(params)
        .map((k) => esc(k) + "=" + esc(params[k]))
        .join("&");
        let totalCountQuery = "";
        if (params.q !== "") {
        totalCountQuery = `q=${params.q}`;
        }
        api.manageUser.getIncompletedChatLists(query).then((data) => {
            try {
                dispatch(setTableData({data:data.data}))
                dataRowRender()
            } catch (err) {
                console.log(err);
            }
        });
    };
   
    
    const dataRowRender = () => {
        let datatableRowstemp;
        if (state.data.totalrecord) {
            datatableRowstemp = state.data.list.map((list, index) => (
            <DatatableRow
                key={index}
                list={list}
                loadData={loadData}
            />
            ));

        } else {
            datatableRowstemp = (
            <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                    No record found.
                </Table.Cell>
            </Table.Row>
            );
        }
        setdatatableRows(datatableRowstemp);
    }

    const onChangeLimit = ( limit,data ) => {
        dispatch(setParams({_limit:data.value}));
        loadData(state.params);
    }
    const onChangePage = ( limit,data ) => {
        dispatch(setParams({_page:data.value}));
        loadData(state.params);
    }
    return (
        <>  {state.headers && (
            <Table celled selectable sortable>
                <DatatableTableHeader header={state.headers}/>
                <Table.Body>{datatableRows}</Table.Body>
                <Table.Footer>
                <Table.Row>
                {state.params._limit && 
                        <Table.HeaderCell colSpan="3">
                        <DatatablePageSizeSelect  
                            limit={state.params._limit} 
                            onChangeLimit={onChangeLimit} /> 
                            Showing 1 - {state.params._limit} of {state.data.totalrecord} 
                        </Table.HeaderCell>
                    }
                    
                     <Table.HeaderCell colSpan="5">
                    {
                        state.data.totalrecord && 
                        <Pagination
                            totalPages={Math.ceil(state.data.totalrecord/state.params._limit)}
                            activePage={state.params._page}
                            onPageChange={onChangePage}
                        />
                    }
                    </Table.HeaderCell>
                </Table.Row>
                </Table.Footer>
            </Table>
        ) } 
        
        </>
    )
}

export default IncompleteChat;