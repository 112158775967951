import React from 'react';
import api from "../../../../api";
import "../../../Grid/grid.css";
import { Segment, Table } from 'semantic-ui-react';
import { DatatableTable } from './DatatableTable';
import { DatatableFilter } from './DatatableFilter';
import DialogBoxDetail from './DialogBoxDetail';
import DialogBoxAddEdit from './DialogBoxAddEdit';
import DialogBoxSetNotificationAndMiscSetting from './DialogBoxSetNotificationAndMiscSetting';
import { DatatableRow } from './DatatableRow';
import utilFunc from "../../../../util_funs";


const tableHeader = [{
  "title": 'Actions',
  "width": '1',
  "sort": '',
},
{
  "title": 'Email',
  "width": '3',
  "sort": 'email',
},
{
  "title": 'Full Name',
  "width": '2',
  "sort": 'name',
},
/* {
  "title": 'Chat Location',
  "width": '2',
  "sort": '',
}, */
{
  "title": 'HIPAA Log',
  "width": '1',
  "sort": '',
},
{
  "title": 'Date Added',
  "width": '2',
  "sort": 'created_date',
},
{
  "title": 'Privileges',
  "width": '1',
  "sort": '',
},
{
  "title": 'Notifications/settings',
  "width": '1',
  "sort": '',
}
]
const queryParams = ['_limit', '_order', '_sort', 'q', '_page'];
const APIURL = 'http://localhost/zyratalk/api/premium/settings/manageuser/';
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: 'ls_user_id',
      _page: 1,
      _order: 'desc',
      _limit: 10,
      q: '',
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalErrorMsgLegacy:false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      selectedOptions: [],
      accessModuleList: [],
      modalstatusNotiAandMisc:false,
      modalstatusNotiAandMiscData: {},
      ISDrenderOption1:[],
      ISDrenderOption2:[],
      notiAndSettingmodalData:{},
      loadNow:false,
      currentChecked:[],
      currentExpanded:[],
      subuser_location:0,
      jumpin_changed:'',
      invalidEmailMsg: '',
      invalidEmail: false
    };

  }

  componentDidMount() {
    this.loadData({});
    // this.getAccessModules();
    api.user
      .getProfile()
      .then((resp) => {
        if (resp.data && resp.data.data.profileDetails) {
          const remailCountryCodeArr = resp.data.data.remailCountryCodeArr;
          const defaultCountryCodeArr = resp.data.data.defaultCountryCodeArr;
         this.setState({ISDrenderOption1:defaultCountryCodeArr})
         this.setState({ISDrenderOption2:remailCountryCodeArr})
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  isMsgchanged=(val)=>{
      this.setState({jumpin_changed:val});
      this.props.jumpin_newmsg(val);
  }

	setCurrent_subuser_location = ( e ) => {
		this.setState({
			...this.state,
			[e.target.name] : e.target.value
		})
	}
  static directionConverter(order) {
    if (order === 'asc') {
      return 'ascending';
    } else if (order === 'desc') {
      return 'descending';
    } else {
      return null;
    }
  }

  handleSort = clickedColumn => {
    const { _sort, _order } = this.state;

    let newOrder = _order === 'asc' ? 'desc' : 'asc';
    if (_sort !== clickedColumn) {
      newOrder = 'asc';
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = filter => {
    if (filter !== this.state.q) {
      this.loadData({ q: filter, _page: 1 });
    }
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  modalbuttofun = (data) => {

    if (data.modalType == 'Delete') {
      this.onDeleteAction();
    }
    if (data.modalType == 'Popup') {
      this.setState({
        modalstatus: false,
        modaltitle: '',
        modalmessage: '',
        modalbuttonmsg: {},
        modalType: ''
      });
    }
  };

  addUserSubAccounts = () => {
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: 'Add User',
      modalType: 'Add', 
      modalFormLoader: true, 
      modaldata: {},
      selectedOptions: [],
      currentExpanded:[],
      
    },()=>{
      new Promise(myResolve => {
        let checkArr = [];
        let i=0;
        this.state.accessModuleList.forEach((list, index) => {
          if(typeof list.children=='undefined'){
            checkArr.push(list.value);
          }else{ 
            checkArr.push(list.children.map((number) => number.value))
          }
          i++;
          if(this.state.accessModuleList.length==i)
          myResolve(checkArr); // when successful
        });

       }).then((data)=>{
          document.getElementById('formManageUser').reset();
          this.setState({  
            currentChecked:data.flat(),
            modalFormLoader: false,
            
          }); 
        })
    });  
   };

  editUserSubAccounts = id => {
    document.getElementById('formManageUser').reset();
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let modaldata = this.state.lists[recordlist];
    // here work by hilal
    // let editDateModule = [];
    // let moduleList = this.state.accessModuleList;
    // moduleList.forEach((item, index) => {
    //   Object.keys(item).forEach(function (key) {
    //     if (modaldata.linksetting.includes(key))
    //       editDateModule.push(key);
    //   });
    // });
    this.setState({
      modalstatusAddEdit: true,
      modaltitle: 'Edit User',
      modalType: 'Edit',
      modaldata: modaldata,
      currentChecked:(modaldata.setting.access_module_lists==null)?[]:JSON.parse(modaldata.setting.access_module_lists),
      currentExpanded:[],
     
      // selectedOptions: editDateModule
    });
  };

  deleteUserSubAccounts = id => {
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let modaldata = this.state.lists[recordlist];
    this.setState({
      modalstatus: true,
      modaltitle: 'Confirm your action',
      modalmessage: 'Are you sure you want to delete?',
      modalbuttonmsg: { "submit": 'Yes', "cancel": 'No' },
      modalType: 'Delete',
      modaldata: modaldata
    });
  };

  setCurrentCheck = (checked) =>{
    this.setState({
      currentChecked:checked,
    })
  }
  setCurrentExpand = (checked)=>{
    this.setState({
      currentExpanded:checked
    })
  }

  onDeleteAction = () => {
    this.setState({ modalFormLoader: true });
    api.manageUser.deleteUser({ "subaccount_id": this.state.modaldata.id }).then(data => {
      this.setState({ modalFormLoader: false });
      try {
        let recordDeleted = this.state.lists.findIndex(
          list => list.id === data.data.id
        );
        let oldArr = this.state.lists;
        oldArr.splice(recordDeleted, 1);
        this.setState({
          datatableRows: this.state.lists.map((list, index) => (
            <DatatableRow key={index} list={list} editUserSubAccounts={this.editUserSubAccounts} deleteUserSubAccounts={this.deleteUserSubAccounts} preOpensetting={this.preOpensetting} openNotificationSetting = {this.openNotificationSetting} />
          )),
          modalstatus: false,
          modaltitle: '',
          modalmessage: '',
          modalbuttonmsg: {},
          modalType: '',
          modalErrorMsg: false
        });
        this.props.showUserToaster('green', 'User has been deleted successfully.');
        utilFunc.scrollTop(800);
      } catch (err) {
        console.log(err);
      }
    });
  };

  preOpensetting = (id) => {
    let recordlist = this.state.lists.findIndex(
      list => list.id === id
    );
    let access_selected = this.state.lists[recordlist].setting.access_module_lists;
    // let access_selected_lists_html = '';
    // access_selected_lists_html = access_selected.map((item, value) =>
    //   <p key={item} style={{ textTransform: "capitalize", color: "#6c7293", fontWeight: "400"}}>{item.replace(/_/g, ' ')}</p>
    // );
    //console.log(this.state.accessModuleList);
    let allcheck = [];
    if(this.state.accessModuleList.length>0 && this.state.lists[recordlist].user_type=='main'){
      new Promise((resolve,reject)=>{
        let defauldList =  this.state.accessModuleList;
        allcheck =  defauldList.map((data,key)=>{
          allcheck.push(data.value.toString());
          if(data.children!== undefined){
            if(data.children.length>0){
              data.children.map((data,key)=>{
                allcheck.push(data.value.toString());
              });
            }
          } 
          if(key==defauldList.length-1)
            resolve(allcheck);
        })
        
      }).then((allcheck)=>{
        this.setState({
          modalstatus: true,
          modaltitle: 'Setting/Privileges',
          modalmessage: "tree",
          modalbuttonmsg: { "submit": 'Ok', "cancel": '' },
          modalType: 'Popup',
          currentChecked:(this.state.lists[recordlist].setting.access_module_lists==null)?allcheck:JSON.parse(this.state.lists[recordlist].setting.access_module_lists),
          currentExpanded:[],
        });
      });
      
    }else{
      this.setState({
        modalstatus: true,
        modaltitle: 'Setting/Privileges',
        modalmessage: "tree",
        modalbuttonmsg: { "submit": 'Ok', "cancel": '' },
        modalType: 'Popup',
        currentChecked:(this.state.lists[recordlist].setting.access_module_lists==null)?allcheck:JSON.parse(this.state.lists[recordlist].setting.access_module_lists),
        currentExpanded:[],
      });
    }
     
  };

  openNotificationSetting = (id) =>{
    // alert("hi")
    // return;
    document.getElementById("managePIN").reset()

    let index = this.state.lists.findIndex(
      list => list.id === id
    );
    let listVal = this.state.lists[index];
    var obj = {
      is_recieve_text_message_on_livechat:listVal.setting.is_recieve_text_message_on_livechat,
      is_recieve_text_message_on_livechatChecked:(listVal.setting.is_recieve_text_message_on_livechat==1)?true:false,
      is_recieve_email_on_livechat:listVal.setting.is_recieve_email_on_livechat,
      is_revieve_gpt_notification_incompleted_chat:listVal.setting.is_revieve_gpt_notification_incompleted_chat,
      is_recieve_email_on_livechatChecked:(listVal.setting.is_recieve_email_on_livechat==1)?true:false,
      is_recieve_notification_on_humanChecked:(listVal.setting.is_recieve_notification_on_human==1)?true:false,
      is_recieve_notification_on_confuseChecked:(listVal.setting.is_recieve_notification_on_confuse==1)?true:false,
      jumpin_message:listVal.setting.jumpin_message,
      is_recieve_text_message_on_completechat:listVal.setting.is_recieve_text_message_on_completechat,
      is_recieve_text_message_on_completechatChecked:(listVal.setting.is_recieve_text_message_on_completechat==1)?true:false,
      is_recieve_email_on_completechat:listVal.setting.is_recieve_email_on_completechat,
      is_recieve_email_on_completechatChecked:(listVal.setting.is_recieve_email_on_completechat==1)?true:false,
      is_leave_note:listVal.setting.is_leave_note,
      is_leave_noteChecked:(listVal.setting.is_leave_note==1)?true:false,
      is_revieve_notification_incompleted_chat:listVal.setting.is_revieve_notification_incompleted_chat,
      is_revieve_notification_incompleted_chatChecked:(listVal.setting.is_revieve_notification_incompleted_chat==1)?true:false,
      phone:listVal.setting.phone,
      isd:(listVal.setting.isd==null || listVal.setting.isd==''?"+1":listVal.setting.isd),
      email:listVal.email,
      name:listVal.name,
      id:listVal.id,
      chatBot_id:listVal.setting.chatBot_id,
      image:listVal.setting.image,
      receive_notification_voice:(listVal.setting.receive_notification_voice==1)?true:false,
      recieve_misc_notification: listVal.setting.recieve_misc_notification,
      is_recieve_misc_notificationChecked:(listVal.setting.recieve_misc_notification==1)?true:false,
    }    
    this.setState({
      modalstatusNotiAandMisc:true,
      loadNow:true,
      notiAndSettingmodalData:obj,


    },this.openotipopup(listVal))

return false;
  }
  openotipopup = (listVal)=>{
    // var obj = {
    //   is_recieve_text_message_on_livechat:listVal.setting.is_recieve_text_message_on_livechat,
    //   is_recieve_text_message_on_livechatChecked:(listVal.setting.is_recieve_text_message_on_livechat==1)?true:false,
    //   is_recieve_email_on_livechat:listVal.setting.is_recieve_email_on_livechat,
    //   is_recieve_email_on_livechatChecked:(listVal.setting.is_recieve_email_on_livechat==1)?true:false,
    //   jumpin_message:listVal.setting.jumpin_message,
    //   is_recieve_text_message_on_completechat:listVal.setting.is_recieve_text_message_on_completechat,
    //   is_recieve_text_message_on_completechatChecked:(listVal.setting.is_recieve_text_message_on_completechat==1)?true:false,
    //   is_recieve_email_on_completechat:listVal.setting.is_recieve_email_on_completechat,
    //   is_recieve_email_on_completechatChecked:(listVal.setting.is_recieve_email_on_completechat==1)?true:false,
    //   is_leave_note:listVal.setting.is_leave_note,
    //   is_leave_noteChecked:(listVal.setting.is_leave_note==1)?true:false,
    //   phone:listVal.setting.phone,
    //   isd:listVal.setting.isd,
    //   email:listVal.email,
    //   name:listVal.name,
    //   id:listVal.id,
    //   chatBot_id:listVal.setting.chatBot_id,
    //   image:listVal.setting.image,
    // }
    // console.log(obj)

    // this.setState({
    //   notiAndSettingmodalData:obj,
    // },this.loadNow)
  }

  loadNow = ()=>{
    document.querySelector(".manageNotification").reset()

  }

  notiAndMiscmodalClose = (id) =>{

    this.setState({
      modalstatusNotiAandMisc:false,
      notiAndSettingmodalData:{}
    })
  }

  modalClose = (flag, fm) => {
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: '',
      modalmessage: '',
      modalbuttonmsg: {},
      modalType: '',
      modaldata: {},
      selectedOptions: [],
	  subuser_location:0
    });
  };

  onValidSubmit = (e) => {
    let sendDate = { "email": e.email, "name": e.name, "password": e.password, "access_modules": JSON.stringify(this.state.currentChecked),'location':this.state.subuser_location };
    console.log('sendDate',e);
    if (this.state.modalType == 'Add' && this.state.currentChecked.length>0) {
      this.setState({ modalFormLoader: true });
      api.manageUser.addUser(sendDate).then(data => {
        this.setState({ modalFormLoader: false });
        try {
          if(!data.status){
            this.setState({
              invalidEmail: true,
              invalidEmailMsg: data.message
            });
            setTimeout(
              function () {
                this.setState({ invalidEmail: false, invalidEmailMsg: '' });
              }
                .bind(this),
              5000
            );
          }else if (data.data.duplicate) {
            this.setState({
              modalErrorMsg: true
            });
            setTimeout(
              function () {
                this.setState({ modalErrorMsg: false });
              }
                .bind(this),
              5000
            );
          } else if(data.data.foundInLegacy){
            this.setState({
              modalErrorMsgLegacy: true
            });
            setTimeout(
              function () {
                this.setState({ modalErrorMsgLegacy: false });
              }
                .bind(this),
              5000
            );
          } else {
            let oldArr = this.state.lists;
            oldArr.unshift(data.data);
            this.setState({
              datatableRows: this.state.lists.map((list, index) => (
                <DatatableRow key={index} list={list} editUserSubAccounts={this.editUserSubAccounts} deleteUserSubAccounts={this.deleteUserSubAccounts} preOpensetting={this.preOpensetting} openNotificationSetting = {this.openNotificationSetting} />
              )),
              totalCount: this.state.totalCount + 1,
              modalstatusAddEdit: false,
              modaltitle: '',
              modalmessage: '',
              modalbuttonmsg: {},
              modalType: '',
              modalErrorMsg: false,
              modaldata: {},
              selectedOptions: []
            });
            this.props.showUserToaster('green', 'User has been added successfully.');
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
    if (this.state.modalType == 'Edit'  && this.state.currentChecked.length>0) {
      this.setState({ modalFormLoader: true });
      sendDate['subaccount_id'] = e.subaccount_id;
      api.manageUser.editUser(sendDate).then(data => {
        
        try {
          this.setState({ modalFormLoader: false });
          if(!data.status){
            this.setState({
              invalidEmail: true,
              invalidEmailMsg: data.message
            });
            setTimeout(
              function () {
                this.setState({ invalidEmail: false, invalidEmailMsg: '' });
              }
                .bind(this),
              5000
            );
          }else if (data.data.duplicate) {
            this.setState({
              modalErrorMsg: true
            });
            setTimeout(
              function () {
                this.setState({ modalErrorMsg: false });
              }
                .bind(this),
              5000
            );
            } else if(data.data.foundInLegacy){
              this.setState({
                modalErrorMsgLegacy: true
              });
              setTimeout(
                function () {
                  this.setState({ modalErrorMsgLegacy: false });
                }
                  .bind(this),
                5000
              );
          } else {
            let recordlist = this.state.lists.findIndex(
              list => list.id === data.data.id
            );
            let modaldata = data.data;
            let updatedRecord = Object.assign([...this.state.lists], {
              [recordlist]: modaldata,
            });
            this.setState({
              datatableRows: updatedRecord.map((list, index) => (
                <DatatableRow key={index} list={list} editUserSubAccounts={this.editUserSubAccounts} deleteUserSubAccounts={this.deleteUserSubAccounts} preOpensetting={this.preOpensetting} openNotificationSetting = {this.openNotificationSetting} />
              )),
              lists: updatedRecord,
              modalstatusAddEdit: false,
              modaltitle: '',
              modalmessage: '',
              modalbuttonmsg: {},
              modalType: '',
              modalErrorMsg: false,
              modaldata: {},
              selectedOptions: []
            });
            console.log("afterupdate", updatedRecord )
            this.props.showUserToaster('green', 'User has been edited successfully.');
            utilFunc.scrollTop(800);
          }

        } catch (err) {
          console.log(err);
        }

      });
    }
  
  }

  loadData = params => {
    const newState = Object.assign({}, this.state, params, { loading: false });
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });
    const esc = encodeURIComponent;
    const query = Object.keys(params)
    .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');

    let totalCountQuery = '';
    if (params.q !== '') {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.manageUser.getLists(query).then(data => {
        try {
          let accessModuleStore = data.user_access;
          // data.user_access.forEach((item, index) => {
          //   item.forEach((item2, index2) => {
          //     accessModuleStore.push(item2);
          //   });
          // });
          let datatableRowstemp; var isd1 = "+1";
          var phoneno = "";
          var is_recieve_text_message_on_livechat = "0";
          var is_recieve_email_on_livechat = "0";
          var jumpin_message = "";
          var is_recieve_text_message_on_completechat = "0";
          var is_recieve_email_on_completechat = "0";
          var is_leave_note = "0";
          console.log("manage user",data.data.list);
          if (data.data.totalrecord) {
            datatableRowstemp = data.data.list.map((list, index) => (<DatatableRow key={index} list={list} editUserSubAccounts={this.editUserSubAccounts} deleteUserSubAccounts={this.deleteUserSubAccounts} preOpensetting={this.preOpensetting} openNotificationSetting = {this.openNotificationSetting} />));
          } else {
            datatableRowstemp = <Table.Row><Table.Cell colSpan={7} textAlign="center">No record found.</Table.Cell></Table.Row>;
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
            accessModuleList: accessModuleStore
          });

        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });

    });

  };

  onValidSubmitFirstCol = (notiAndSettingmodalData,id)=>{
    let index = this.state.lists.findIndex(
      list => list.id === id
    );
    this.state.lists[index].setting.isd =  notiAndSettingmodalData.isd;
    this.state.lists[index].setting.image =  notiAndSettingmodalData.image;
    this.state.lists[index].setting.phone =  notiAndSettingmodalData.phone;
    this.setState({
      lists:this.state.lists,
    })

  }
  onValidSubmitSecondCol = (notiAndSettingmodalData,id)=>{
    let index = this.state.lists.findIndex(
      list => list.id === id
    );
    this.state.lists[index].setting.jumpin_message =  notiAndSettingmodalData.jumpin_message;
    this.setState({
      lists:this.state.lists,
    })


  }

  onValidSubmitSwicth = (notiAndSettingmodalData,id)=>{
    let index = this.state.lists.findIndex(
      list => list.id === id
    );
    this.state.lists[index].setting.is_recieve_text_message_on_livechat =  notiAndSettingmodalData.is_recieve_text_message_on_livechat;
    this.state.lists[index].setting.is_recieve_email_on_livechat =  notiAndSettingmodalData.is_recieve_email_on_livechat;
    this.state.lists[index].setting.is_recieve_text_message_on_completechat =  notiAndSettingmodalData.is_recieve_text_message_on_completechat;
    this.state.lists[index].setting.is_recieve_email_on_completechat =  notiAndSettingmodalData.is_recieve_email_on_completechat;
    this.state.lists[index].setting.is_leave_note =  notiAndSettingmodalData.is_leave_note;
    this.state.lists[index].setting.is_revieve_notification_incompleted_chat =  notiAndSettingmodalData.is_revieve_notification_incompleted_chat;
    this.state.lists[index].setting.receive_notification_voice =  notiAndSettingmodalData.receive_notification_voice;
    this.setState({
      lists:this.state.lists,
    })
  }
  

  render() {
    return (
      <Segment>
        {this.state.loading && <div className="ui loading form"></div>}
        <DatatableFilter
          filter={this.state.q}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
        />
        <DialogBoxDetail
          obj={this.state}
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
          currentChecked={this.state.currentChecked}
          currentExpanded = {this.state.currentExpanded}
          setCurrentCheck = {this.setCurrentCheck}
          setCurrentExpand = {this.setCurrentExpand}
        />
        <DialogBoxAddEdit
          obj={this.state}
          modalClose={this.modalClose}
          onValidSubmit={this.onValidSubmit}
          currentChecked={this.state.currentChecked}
          currentExpanded = {this.state.currentExpanded}
          setCurrentCheck = {this.setCurrentCheck}
          setCurrentExpand = {this.setCurrentExpand}
          legacybuttons={this.props.legacybuttons}
          setSubUserLocation = {this.setCurrent_subuser_location}
        />
        <DialogBoxSetNotificationAndMiscSetting
          obj={this.state}
          notiAndMiscmodalClose={this.notiAndMiscmodalClose}
          onValidSubmitFirstCol={this.onValidSubmitFirstCol}
          onValidSubmitSecondCol = {this.onValidSubmitSecondCol}
          onValidSubmitSwicth = {this.onValidSubmitSwicth}
          sessionUser = {this.props.sessionUser}
          loadNow= {this.state.loadNow}
          isMsgchanged={this.isMsgchanged}
        />
      </Segment>
    );
  }
}
