import React,{useState} from 'react';
import Datatable from './MonthlyReengaeDataTable/index';
function MonthlyReengage( props ){
    // const [tableElements,setTableElement] = useState({
    //     columns : [
    //         { text: "S.no", key: "sno", sort: false },
    //         { text: "TYPE", key: "reason", sort: true },
    //         { text: "Time/Date", key: "transaction_time", sort: true },
    //         { text: "Amount", key: "amount", sort: true },
    //     ]
    // });
    return(
        <React.Fragment>
            <Datatable 
                // dataColumns={tableElements.columns}
                userid = {props.userId}
                billingdetails={props.obj}
            />
        </React.Fragment>
    );
}
export default MonthlyReengage;