import React,{useState} from "react";
import { useSelector,useDispatch } from "react-redux";
import { Table, } from "semantic-ui-react";

import PropTypes from "prop-types";
import DialogBoxDetail from "./DialogBoxDetail";
import DialogBoxDetailEdit from "./DialogBoxDetailEdit";
import api from "../../../../../api";
import { setTableData } from "../../../../../store/incompleteChat";

export function DatatableRow(props){
  const state = useSelector((state) => state.incompletechat);
  const [modalState,setModalState] = useState({
    modalstatus: false,
    modaltitle: "Confirm your action",
    modalmessage: "Are you sure you want to delete?",
    modalbuttonmsg: { submit: "Yes", cancel: "No" },
    modalType: "Delete",
    modaldata: null,
    modalFooter:true
  });
  
  const [rowid,setrowid] = useState("");
  const [show,setShow] = useState(false);
  const dispatch = useDispatch();

  const deleteCompletedChat = (id) => {
    const temstate  = {
      modalstatus: true,
      modaltitle: "Confirm your action",
      modalmessage: "Are you sure you want to delete?",
      modalbuttonmsg: { submit: "Yes", cancel: "No" },
      modalType: id,
      modaldata: id,
      modalFooter:true
    };
    setModalState(temstate);
  };

  
  const Modalclose = () => {
    setShow(false);
  }
  const closeModal = () => {
    let temstate = {...modalState}
    temstate.modalstatus = false;
    temstate.modalType = null;
    setModalState(temstate);
  }
  const deleteCompletedChatProcess = (id ) => {
    console.log("logconsole",id.modalType);
    api.manageUser.deleteIncompleteChatEmail(id.modalType).then((data) => {
      try {
        props.loadData({});
        closeModal();
      } catch (err) {
          console.log(err);
      }
    });
  }

  const editMember = (id) => {
    setrowid(id);
    setShow(true);
}
const refreshData=(id,dta)=>{
  console.log(state.data,"resfresh data",id,dta);
  let st=[...state.data.list];
  if(st.length>0){
     let ind= st.findIndex(s=>s.id==dta.id);
     console.log(ind,"resfresh data");
     if(ind>=0){
       st[ind]={...dta};
       console.log(st,"resfresh data");
       dispatch(setTableData({data:st}));
     }
  }
  //props.loadData({});
}

  return (
    <Table.Row>
      <Table.Cell>
        {props.list.user_type != "main" ? (
         <> <span>
            <a
              onClick={() => deleteCompletedChat(props.list.id)}
              title="Delete"
              className="btn btn-icon gray"
            >
              <i className="la la-trash"></i>
            </a>{" "}
          </span>
          <span>
          <a
            onClick={() => editMember(props.list.id)}
            title="Edit"
            className="btn btn-icon gray"
          >
            <i className="la la-edit blue"></i>
          </a>{" "}
        </span>
        </>
        ) : (
          ""
        )}
      </Table.Cell>
      <Table.Cell>
        {props.list.email == ""
          ? props.list.isd_code + " " + props.list.phone_number
          : props.list.name}
      </Table.Cell>
      <Table.Cell>
        {props.list.email 
          ? props.list.email
          : ''}
      </Table.Cell>
      <DialogBoxDetail
          obj={modalState}
          show={modalState.modalstatus}
          title={modalState.modaltitle}
          message={modalState.modalmessage}
          modalType={modalState.modalType}
          modalClose={closeModal}
          buttonmsg={modalState.modalbuttonmsg}
          modalbuttofun={deleteCompletedChatProcess}
        />
        {show && <DialogBoxDetailEdit
          id={rowid}
          list={props.list ? props.list:[]}
          show={show}
          closeModal={Modalclose}
          reloadGrid={refreshData}
        />}
    </Table.Row>
  )
};

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
